import React, { useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { message, Input, Select } from 'antd'
import CustomQuillEditor from '../components/CustomQuillEditor'
import TagInput from '../components/TagInput'
import { useAuth } from '../hooks/AuthContext'
import api from '../utils/api'

const Container = styled.div`
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`

const Box = styled.div`
  margin-left: 10px;
`

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
  text-align: left;
`

const ArticleTitle = styled.div`
  display: flex;
  margin-bottom: 10px;
  max-width: 680px;
  border-radius: 10px;
`

const Form = styled.form`
  display: flex;
  flex: 3;
  flex-direction: column;
`

const SubmitButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  background-color: #d5728a;
  color: white;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
`

const FormWrapper = styled.div`
  display: flex;
  gap: 20px;
`

const AddNewArticle = () => {
  const navigate = useNavigate()
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [imagePreview] = useState(null)
  const [tags, setTags] = useState([])
  const [category, setCategory] = useState('beauty')
  const { user } = useAuth()
  const [coverImageUrl, setCoverImageUrl] = useState('')

  const handleCoverImageChange = (imageUrl) => {
    setCoverImageUrl(imageUrl)
    console.log('Cover image changed:', imageUrl)
  }

  const handleContentImageUpload = async (file) => {
    const formData = new FormData()
    formData.append('file', file)

    try {
      const response = await api.post(`/api/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      const { imageUrl } = response.data
      return imageUrl
    } catch (error) {
      message.error('圖片上傳失敗')
      return null
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!title || !content || !category || !tags.length || !coverImageUrl) {
      message.error('所有字段都是必填项，包括封面图片')
      return
    }

    console.log('Cover Image URL before submission:', coverImageUrl)

    try {
      const articleData = {
        title,
        content,
        image: coverImageUrl,
        tags,
        category,
        userId: user.userId,
        username: user.username
      }

      const response = await api.post(`/api/articles`, articleData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      message.success('文章新增成功')
      console.log('response', response)
      navigate('/')
    } catch (error) {
      console.error('Error creating article:', error)
      message.error('新增文章失败')
    }
  }

  return (
    <Container>
      <Title>新增文章</Title>
      <FormWrapper>
        <Form onSubmit={handleSubmit}>
          <ArticleTitle>
            <Input
              type='text'
              placeholder='標題'
              size='large'
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
            <Box>
              <Select
                value={category}
                onChange={setCategory}
                placeholder='分類'
                style={{ width: 110, height: 40 }}
                required
              >
                <Select.Option value='beauty'>美容保養</Select.Option>
                <Select.Option value='fashion'>時尚話題</Select.Option>
                <Select.Option value='art'>人文藝術</Select.Option>
                <Select.Option value='motivation'>心靈勵志</Select.Option>
                <Select.Option value='hair'>髮型風格</Select.Option>
                <Select.Option value='surgery'>醫美話題</Select.Option>
                <Select.Option value='body'>美體養生</Select.Option>
                <Select.Option value='nail'>美甲設計</Select.Option>
              </Select>
            </Box>
          </ArticleTitle>
          <CustomQuillEditor
            value={content}
            onChange={setContent}
            onImageUpload={handleContentImageUpload}
            onCoverImageChange={handleCoverImageChange}
            coverImagePreview={imagePreview}
          />
          <TagInput tags={tags} setTags={setTags} />
          <SubmitButton type='submit'>提交</SubmitButton>
        </Form>
      </FormWrapper>
    </Container>
  )
}

export default AddNewArticle
