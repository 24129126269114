import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Carousel } from 'antd'
import {
  CalendarOutlined,
  EnvironmentOutlined,
  StarOutlined,
  TagOutlined
} from '@ant-design/icons'
import { Button, Popup } from 'antd-mobile'
import { Helmet } from 'react-helmet-async'
import moment from 'moment-timezone'
import 'moment/locale/zh-cn'
import BlogPage from './BlogPage'
import { PHOTO_URL, API_BASE_URL } from '../utils/apiutils'
import { colors } from '../utils/utils'
import { motion, useAnimation } from 'framer-motion'
import axios from 'axios'
import LazyLoad from 'react-lazyload'
import { useLocation, useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { LocalizationProvider, DateCalendar } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { promotionItems } from '../utils/promotion.js'
import TestimonialSlider from '../components/TestimonialSlider'

// 修改現有的styled components
const Container = styled.div`
  font-family: 'Noto Sans TC', sans-serif;
  overflow: hidden;
  background-color: ${colors.background};
  color: ${colors.text};
`

const HeroContent = styled.div`
  text-align: left;
  z-index: 2;
  color: #fff;
  position: absolute;
  top: 30%;
  left: 25%;
  transform: translate(-50%, -50%);
  @media (max-width: 820px) {
    top: 40%;
    left: 25%;
  }
`

const HeroTitle = styled.h1`
  font-size: 3rem;
  margin-bottom: 10px;
  @media (max-width: 820px) {
    font-size: 18px;
    margin-bottom: 0px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`

const HeroSubtitle = styled.p`
  font-size: 1.5rem;
  @media (max-width: 820px) {
    font-size: 16px;
  }
  @media (max-width: 480px) {
    font-size: 12px;
    margin-top: 0;
  }
`

const HeroButton = styled(motion.button)`
  background-color: ${colors.primary};
  color: ${colors.background};
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background-color: ${colors.secondary};
    color: ${colors.primary};
  }
`

const HeroSlider = styled(Carousel)`
  .slick-dots li.slick-active button:before {
    display: none;
  }
  .slick-dots li button:before {
    display: none;
  }
  .slick-slide {
    height: 100%;
  }
  .slick-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (max-width: 820px) {
    .slick-slide {
      height: 40vh;
    }
    .slick-slide img {
      height: 40vh;
    }
  }
  @media (max-width: 480px) {
    .slick-slide {
      height: 30vh;
    }
    .slick-slide img {
      height: 30vh;
    }
  }
`

const HeroOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to right,
    rgba(84, 84, 84, 0.5) 0%,
    rgba(84, 84, 84, 0.4) 30%,
    rgba(84, 84, 84, 0.2) 60%,
    rgba(84, 84, 84, 0) 100%
  );
  z-index: 1;
`

const sliderImages = [
  '/assets/bgpink.png',
  '/assets/forbg.png',
  '/assets/pink.png'
]

const WorksCarousel = styled(Carousel)`
  .slick-slide {
    padding: 0 10px;
    height: 250px;
  }
  .slick-track {
    height: 50px;
  }
  .slick-list {
    height: 280px;
  }
  @media (max-width: 820px) {
    .slick-dots li.slick-active button:before {
      display: none;
    }
    .slick-dots li button:before {
      display: none;
    }
    .slick-slide {
      height: 150px;
    }
    .slick-list {
      height: 150px;
    }
  }
`

const WorkCard = styled(motion.div)`
  position: relative;
  background: #f6f6f6;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  .slick-slide {
    width: 100%;
    height: 280px;
  }
  &:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  @media (max-width: 820px) {
    width: 100%;
    height: 280px;
    .slick-slide {
      height: 280px;
    }
  }
  cursor: pointer;
`

const WorkOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 12px;
  opacity: 0;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }
`

const WorkTitle = styled.h3`
  color: white;
  font-size: 18px;
  margin-bottom: 5px;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

const TitleLine = styled.div`
  flex-grow: 1;
  height: 4px;
  background-color: #f6f6f6;
`

const Title = styled.h1`
  font-size: 23px;
  font-weight: 600;
  color: #333;
  margin: 0 20px;
  white-space: nowrap;
`

// 新的LazyImage組件
const LazyImage = ({ src, alt, ...props }) => (
  <LazyLoad height={200} once>
    <img src={src} alt={alt} {...props} />
  </LazyLoad>
)

const AnimatedSection = styled(motion.div)`
  margin-bottom: 40px;
  margin-top: ${(props) => (props.testimonial ? '50px' : '0')};
  @media (max-width: 820px) {
    margin-bottom: 20px;
  }
  @media (max-width: 480px) {
    margin-bottom: 10px;
  }
`

const GradientBackground = styled.div`
  background: linear-gradient(135deg, #f9ebed 0%, #d45d7a 100%);
`

const QuickSearchSection = styled.div`
  background-color: #fff;
  padding: 30px 100px;
  border-radius: 20px;
  margin-top: -50px;
  position: relative;
  z-index: 10;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  @media (max-width: 820px) {
    padding: 30px 10px;
  }
`

const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`

const SearchInput = styled.input`
  flex-grow: 1;
  padding: 12px 20px;
  border: 1px solid #ddd;
  border-radius: 25px;
  font-size: 16px;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: ${colors.primary};
    box-shadow: 0 0 0 2px rgba(213, 114, 138, 0.2);
  }
`

// 添加新的 styled component
const LoadingSpinner = styled.div`
  border: 2px solid #f3f3f3;
  border-top: 2px solid ${colors.primary};
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
  margin: 0 auto;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

// 修改 SearchButton 組件
const SearchButton = styled.button`
  background-color: ${(props) => (props.disabled ? '#d5728a' : colors.primary)};
  color: white;
  border: none;
  border-radius: 25px;
  padding: 12px 24px;
  margin-left: 10px;
  font-size: 16px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;

  &:hover {
    background-color: ${(props) => (props.disabled ? '#ccc' : '#c4617a')};
    transform: ${(props) => (props.disabled ? 'none' : 'translateY(-2px)')};
    box-shadow: ${(props) =>
      props.disabled ? 'none' : '0 4px 8px rgba(0, 0, 0, 0.15)'};
  }
`

const QuickLinks = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const QuickLink = styled.div`
  flex-basis: calc(25% - 10px);
  text-align: center;
  margin-bottom: 10px;
  cursor: pointer;
`

const QuickLinkIcon = styled.div`
  background-color: white;
  color: #bf1c5f;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 5px;
  font-size: 24px;
`

const QuickLinkText = styled.span`
  font-size: 14px;
  color: ${colors.text};
`

// 新增的styled components
const CategoryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 20px;
`

const CategoryCard = styled.div`
  position: relative;
  height: 100px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`

const CategoryImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const CategoryOverlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50%; // 設置高度為50%，只覆蓋下半部分
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0)
  ); // 使用漸變背景
  display: flex;
  align-items: flex-end; // 將內容對齊到底部
  justify-content: center;
  padding-bottom: 10px; // 添加一些底部內邊距
  transition: height 0.3s ease; // 添加過渡效果

  &:hover {
    height: 100%; // 懸停時覆蓋整個區域
  }
`

const CategoryTitle = styled.h3`
  color: white;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px; // 添加一些底部外邊距
`
const TitlesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  margin: 20px 100px;
  cursor: pointer;
  @media (max-width: 820px) {
    margin: 20px 10px;
  }
`

const TitleBar = styled.div`
  width: 5px;
  height: 18px;
  margin-right: 10px;
  background-color: #d5728a;
`
const Titles = styled.div`
  font-size: 18px;
  color: #333;
  font-weight: bold;
`

// 新增的 styled components
const PromotionSection = styled.div`
  margin: 20px 0;
  padding: 0 100px;
  @media (max-width: 820px) {
    padding: 0 10px;
  }
`

const PromotionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  @media (max-width: 820px) {
    gap: 10px;
  }
`

const PromotionItem = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #f0f0f0;
  @media (max-width: 820px) {
    padding-bottom: 10px;
  }
`

const PromotionImage = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 15px;
  cursor: pointer;
`

const PromotionContent = styled.div`
  flex: 1;
  text-align: left;
  cursor: pointer;
`

const MobilePromotionCarousel = styled(Carousel)`
  .slick-slide > div {
    margin: 0 10px 30px 0px;
  }

  @media (min-width: 768px) {
    .slick-slide > div {
      display: none !important;
    }
    :before {
      display: none;
    }
  }
`

const DesktopPromotionGrid = styled(PromotionGrid)`
  display: none;

  @media (min-width: 768px) {
    display: grid;
  }
`

const PromotionTitle = styled.h3`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0px;
  color: #333;
  @media (max-width: 820px) {
    font-size: 14px;
  }
`

const PromotionDescription = styled.p`
  font-size: 14px;
  color: #666;
  margin-top: 3px;
  margin-bottom: 3px;
  @media (max-width: 820px) {
    font-size: 13px;
  }
`

const PromotionPrice = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${colors.primary};
`
const FeaturedWorksSection = styled.div`
  margin: 20px 0;
  padding: 0 100px;
  @media (max-width: 820px) {
    padding: 0 10px;
  }
`

const StyledPopup = styled(Popup)`
  .adm-popup-body {
    height: 100%;
    background-color: #fff;
  }

  @media (min-width: 1500px) {
    .adm-popup-body {
      height: 60%;
    }
  }

  @media (max-width: 820px) {
    display: flex;
    flex-direction: column;
    .adm-popup-body {
      height: 100%;
    }
  }
  @media (max-width: 480px) {
    .adm-popup-body {
      height: 100%;
    }
  }
`

const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 600px;
  height: 100%;
  @media (max-width: 820px) {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 480px) {
    width: 100%;
    height: 100%;
  }
`

const Header = styled.div`
  background-color: #d5728a;
  color: white;
  padding: 15px;
  text-align: center;
  font-size: 18px;
`

const CalendarWrapper = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`

const CustomDateCalendar = styled(DateCalendar)`
  && {
    width: 100%;
    .MuiPickersDay-root {
      &:hover {
        background-color: #f2b3c0;
      }
    }

    .Mui-selected {
      background-color: #d5728a;

      &:hover {
        background-color: #f2b3c0;
      }
    }
    .MuiPickersDay-root.Mui-selected {
      background-color: #d5728a;
    }

    @media (max-width: 820px) {
      .MuiPickersCalendarHeader-root {
        margin-top: 20px;
        padding-left: 15px;
        padding-right: 10px;
      }
      .MuiDayCalendar-header,
      .MuiDayCalendar-weekContainer {
        justify-content: space-between;
        padding: 0 10px;
      }
      .MuiTypography-root {
        font-size: 15px;
      }
      .MuiPickersDay-root {
        font-size: 16px;
      }
    }
  }
`

const TimeSelectionWrapper = styled.div`
  padding: 0 20px 20px 20px;
`

const TimeSelectionTitle = styled.h3`
  margin-bottom: 10px;
  color: #333;
`

const TimeSelection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`

const TimeDropdown = styled.select`
  width: 40%;
  padding: 12px;
  border: 1px solid #d5728a;
  border-radius: 8px;
  font-size: 16px;
  color: #333;
  background-color: #fff;
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23d5728a%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: right 12px top 50%;
  background-size: 12px auto;

  &:focus {
    outline: none;
    border-color: #bf5170;
  }

  &:disabled {
    background-color: #f0f0f0;
    color: #888;
  }
`

const TimeSeparator = styled.span`
  font-size: 16px;
  color: #333;
`

const Message = styled.p`
  color: #666;
  font-size: 16px;
  text-align: center;
  @media (max-width: 820px) {
    margin: 0;
  }
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px;
`

const StyledButton = styled(Button)`
  width: 45%;
  height: 44px;
  font-size: 16px;
`

// 新增的 styled components
const PromotionCodeWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-left: 10px;
`

const PromotionCode = styled.div`
  background-color: #f0f0f0;
  border: 1px dashed #d5728a;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold;
  color: #d5728a;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #d5728a;
    color: white;
  }
  @media (max-width: 820px) {
    font-size: 13px;
    padding: 3px 5px;
  }
`

const PromotionActions = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: auto;
  p {
    margin-top: 0;
    margin-bottom: 2px;
  }
  @media (max-width: 820px) {
    flex-direction: column;
  }
`

// 新增的 styled components
const TagContainer = styled.div`
  display: flex;
  align-items: flex-end;
`

const Tag = styled.span`
  background-color: #f9ebed;
  color: #d5728a;
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 12px;
  margin-right: 5px;
`

const PromotionTitleWrapper = styled.div`
  display: flex;
`
const SubTitle = styled.h3`
  font-size: 18px;
  color: #666;
  margin-top: 5px;
  text-align: center;
`

const HomePage = () => {
  const [featuredWorks, setFeaturedWorks] = useState([])
  const controls = useAnimation()
  const sectionRefs = useRef([])
  const location = useLocation()
  const searchRef = useRef(null)
  const [searchKeyword, setSearchKeyword] = useState('')
  const navigate = useNavigate()
  const [popupVisible, setPopupVisible] = useState(false)
  const [selectedDate, setSelectedDate] = useState(null)
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')
  const [message, setMessage] = useState('請選擇日期')
  const [copiedCode, setCopiedCode] = useState(null)
  const [isSearching, setIsSearching] = useState(false)

  // 添加今天的日期作為最小日期
  const today = moment().startOf('day')

  useEffect(() => {
    if (location.hash === '#search') {
      const searchSection = document.getElementById('search')
      if (searchSection) {
        searchSection.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [location])

  // 添加 scrollToSection 函數
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId)
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' })
    }
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            controls.start('visible')
          }
        })
      },
      { threshold: 0.1 }
    )

    sectionRefs.current.forEach((ref) => ref && observer.observe(ref))

    return () => observer.disconnect()
  }, [controls])

  useEffect(() => {
    const fetchFeaturedWorks = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/stylist/random-portfolios`
        )
        setFeaturedWorks(response.data)
      } catch (error) {
        console.error('獲取精選作品失敗:', error)
      }
    }

    fetchFeaturedWorks()
  }, [])

  const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } }
  }

  const handleSearch = async () => {
    if (!searchKeyword.trim() || isSearching) return

    setIsSearching(true)
    try {
      const response = await axios.get(`${API_BASE_URL}/api/search/all`, {
        params: { keyword: searchKeyword }
      })

      navigate('/search-all-results', {
        state: {
          results: response.data,
          keyword: searchKeyword
        }
      })
    } catch (error) {
      console.error('搜索失敗:', error)
      // 這裡可以添加錯誤處理
    } finally {
      setIsSearching(false)
    }
  }

  const handleDateSearch = () => {
    setPopupVisible(true)
  }

  const handlePopupClose = () => {
    setPopupVisible(false)
    setSelectedDate(null)
    setStartTime('')
    setEndTime('')
    setMessage('請選擇日期')
  }

  const handleDateChange = (date) => {
    setSelectedDate(date)
    setMessage('日期已選擇,可以選擇時間範圍(可選)')
  }

  const handleStartTimeChange = (e) => {
    setStartTime(e.target.value)
    if (endTime && e.target.value > endTime) {
      setEndTime('')
    }
  }

  const handleEndTimeChange = (e) => {
    if (e.target.value >= startTime) {
      setEndTime(e.target.value)
    } else {
      setEndTime('')
    }
  }

  const handleSearchTime = () => {
    const searchParams = new URLSearchParams()
    if (selectedDate) {
      searchParams.append('date', selectedDate.format('YYYY-MM-DD'))
    }
    if (startTime) {
      searchParams.append('timeStart', startTime)
    }
    if (endTime) {
      searchParams.append('timeEnd', endTime)
    }
    navigate(`/advanced-search?${searchParams.toString()}`)
    setPopupVisible(false)
  }

  const generateTimeOptions = () => {
    const options = [
      <option key='unspecified' value=''>
        不指定
      </option>
    ]
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const time = moment().hour(hour).minute(minute).format('HH:mm')
        options.push(
          <option key={time} value={time}>
            {time}
          </option>
        )
      }
    }
    return options
  }

  // 添加一個函數來格式化星期幾
  const formatWeekday = (weekday) => {
    const weekdays = ['日', '一', '二', '三', '四', '五', '六']
    return weekdays[weekday.day()]
  }

  const handleNearbyClick = () => {
    navigate('/nearby-salons')
  }

  const handleCopyCode = (code) => {
    navigator.clipboard.writeText(code).then(() => {
      setCopiedCode(code)
      setTimeout(() => setCopiedCode(null), 2000)
    })
  }

  // 修改跳轉函數指向優惠區域
  const scrollToSearch = () => {
    navigate('/#promotion')
    const element = document.getElementById('promotion')
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  useEffect(() => {
    if (location.hash === '#promotion') {
      const element = document.getElementById('promotion')
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [location])

  return (
    <>
      <Helmet>
        <title>{`美感｜BeauteFeel-全台美業設計師線上預約平台-掌握美業趨勢與優惠`}</title>
        <meta
          name='description'
          content={`全台美容線上預約平台，不論美甲美容美髮美睫霧唇霧眉，輕鬆一鍵查詢並完成預約，找到最符合您需求的美容專家!`}
        />
      </Helmet>
      <Container>
        {/* Hero Section */}
        <AnimatedSection
          ref={(el) => (sectionRefs.current[0] = el)}
          initial='hidden'
          animate={controls}
          variants={sectionVariants}
        >
          <HeroSlider autoplay effect='fade' id='hero'>
            {sliderImages.map((src, index) => (
              <GradientBackground key={index}>
                <LazyImage src={`${src}`} alt={`Slide ${index + 1}`} />
                <HeroOverlay />
                <HeroContent>
                  <motion.div
                    initial={{ y: 50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.5, duration: 0.8 }}
                  >
                    <HeroTitle>全台美業即時預約平台</HeroTitle>
                    <HeroSubtitle>快速尋找、預約您的專屬設計師</HeroSubtitle>
                    <HeroButton
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => scrollToSection('quick-search')}
                    >
                      立即搜索
                    </HeroButton>
                  </motion.div>
                </HeroContent>
              </GradientBackground>
            ))}
          </HeroSlider>
        </AnimatedSection>

        {/* 新增的快速搜索部分 */}
        <AnimatedSection
          ref={(el) => (sectionRefs.current[1] = el)}
          initial='hidden'
          animate={controls}
          variants={sectionVariants}
          id='quick-search'
        >
          <QuickSearchSection>
            <SearchContainer>
              <SearchInput
                placeholder='搜索美容服務、沙龍或造型師'
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && !isSearching) {
                    handleSearch()
                  }
                }}
                disabled={isSearching}
              />
              <SearchButton
                onClick={handleSearch}
                disabled={isSearching || !searchKeyword.trim()}
              >
                {isSearching ? <LoadingSpinner /> : '搜索'}
              </SearchButton>
            </SearchContainer>
            <QuickLinks>
              <QuickLink onClick={handleDateSearch}>
                <QuickLinkIcon>
                  <CalendarOutlined />
                </QuickLinkIcon>
                <QuickLinkText>按日期查找</QuickLinkText>
              </QuickLink>
              <QuickLink onClick={handleNearbyClick}>
                <QuickLinkIcon>
                  <EnvironmentOutlined />
                </QuickLinkIcon>
                <QuickLinkText>附近沙龍</QuickLinkText>
              </QuickLink>
              <QuickLink onClick={() => navigate('/services')}>
                <QuickLinkIcon>
                  <StarOutlined />
                </QuickLinkIcon>
                <QuickLinkText>熱門服務</QuickLinkText>
              </QuickLink>
              <QuickLink onClick={scrollToSearch}>
                <QuickLinkIcon>
                  <TagOutlined />
                </QuickLinkIcon>
                <QuickLinkText>優惠活動</QuickLinkText>
              </QuickLink>
            </QuickLinks>
            <CategoryGrid>
              <CategoryCard onClick={() => navigate('/businesses')}>
                <CategoryImage src='/assets/haircut.jpg' alt='找店家' />
                <CategoryOverlay>
                  <CategoryTitle>找店家</CategoryTitle>
                </CategoryOverlay>
              </CategoryCard>
              <CategoryCard onClick={() => navigate('/stylists')}>
                <CategoryImage src='/assets/nailmodel.webp' alt='找設計師' />
                <CategoryOverlay>
                  <CategoryTitle>找設計師</CategoryTitle>
                </CategoryOverlay>
              </CategoryCard>
            </CategoryGrid>
          </QuickSearchSection>
        </AnimatedSection>

        <TitlesContainer>
          <TitleBar />
          <Titles>線上專屬優惠</Titles>
        </TitlesContainer>

        <AnimatedSection
          id='promotion'
          ref={(el) => (sectionRefs.current[2] = el)}
          initial='hidden'
          animate={controls}
          variants={sectionVariants}
        >
          <PromotionSection>
            <MobilePromotionCarousel>
              <PromotionGrid>
                {promotionItems.slice(0, 3).map((item, index) => (
                  <PromotionItem key={index}>
                    <PromotionImage
                      src={item.image}
                      alt={`優惠${index + 1}`}
                      onClick={() => navigate(item.link)}
                    />
                    <PromotionContent onClick={() => navigate(item.link)}>
                      <PromotionTitleWrapper>
                        <TagContainer>
                          {item.tags &&
                            item.tags.map((tag, tagIndex) => (
                              <Tag key={tagIndex}>{tag}</Tag>
                            ))}
                        </TagContainer>
                        <PromotionTitle>{item.title}</PromotionTitle>
                      </PromotionTitleWrapper>
                      <PromotionDescription>
                        {item.description}
                      </PromotionDescription>
                      <PromotionPrice>{item.price}</PromotionPrice>
                    </PromotionContent>
                    <PromotionActions>
                      <p>折扣碼:</p>
                      {item.discountCode && (
                        <PromotionCodeWrapper>
                          <PromotionCode
                            onClick={() => handleCopyCode(item.discountCode)}
                          >
                            {copiedCode === item.discountCode
                              ? '已複製!'
                              : item.discountCode}
                          </PromotionCode>
                        </PromotionCodeWrapper>
                      )}
                    </PromotionActions>
                  </PromotionItem>
                ))}
              </PromotionGrid>
              <PromotionGrid>
                {promotionItems.slice(3, 6).map((item, index) => (
                  <PromotionItem key={index + 3}>
                    <PromotionImage
                      src={item.image}
                      alt={`優惠${index + 4}`}
                      onClick={() => navigate(item.link)}
                    />
                    <PromotionContent onClick={() => navigate(item.link)}>
                      <PromotionTitleWrapper>
                        <TagContainer>
                          {item.tags &&
                            item.tags.map((tag, tagIndex) => (
                              <Tag key={tagIndex}>{tag}</Tag>
                            ))}
                        </TagContainer>
                        <PromotionTitle>{item.title}</PromotionTitle>
                      </PromotionTitleWrapper>
                      <PromotionDescription>
                        {item.description}
                      </PromotionDescription>
                      <PromotionPrice>{item.price}</PromotionPrice>
                    </PromotionContent>
                    <PromotionActions>
                      <p>折扣碼:</p>
                      {item.discountCode && (
                        <PromotionCodeWrapper>
                          <PromotionCode
                            onClick={() => handleCopyCode(item.discountCode)}
                          >
                            {copiedCode === item.discountCode
                              ? '已複製!'
                              : item.discountCode}
                          </PromotionCode>
                        </PromotionCodeWrapper>
                      )}
                    </PromotionActions>
                  </PromotionItem>
                ))}
              </PromotionGrid>
            </MobilePromotionCarousel>
            <DesktopPromotionGrid>
              {promotionItems.map((item, index) => (
                <PromotionItem key={index}>
                  <PromotionImage
                    src={item.image}
                    alt={`優惠${index + 1}`}
                    onClick={() => navigate(item.link)}
                  />
                  <PromotionContent onClick={() => navigate(item.link)}>
                    <PromotionTitleWrapper>
                      <TagContainer>
                        {item.tags &&
                          item.tags.map((tag, tagIndex) => (
                            <Tag key={tagIndex}>{tag}</Tag>
                          ))}
                      </TagContainer>
                      <PromotionTitle>{item.title}</PromotionTitle>
                    </PromotionTitleWrapper>
                    <PromotionDescription>
                      {item.description}
                    </PromotionDescription>
                    <PromotionPrice>{item.price}</PromotionPrice>
                  </PromotionContent>
                  <PromotionActions>
                    <p>折扣碼:</p>
                    {item.discountCode && (
                      <PromotionCodeWrapper>
                        <PromotionCode
                          onClick={() => handleCopyCode(item.discountCode)}
                        >
                          {copiedCode === item.discountCode
                            ? '已複製!'
                            : item.discountCode}
                        </PromotionCode>
                      </PromotionCodeWrapper>
                    )}
                  </PromotionActions>
                </PromotionItem>
              ))}
            </DesktopPromotionGrid>
          </PromotionSection>
        </AnimatedSection>

        {/* Search Section */}
        <AnimatedSection
          ref={(el) => {
            sectionRefs.current[3] = el
            searchRef.current = el
          }}
          initial='hidden'
          animate={controls}
          variants={sectionVariants}
          id='search'
        >
          {/* <SearchPage /> */}
        </AnimatedSection>

        {/* Featured Works Section */}
        <AnimatedSection
          ref={(el) => (sectionRefs.current[4] = el)}
          initial='hidden'
          animate={controls}
          variants={sectionVariants}
        >
          <FeaturedWorksSection>
            <TitleContainer>
              <TitleLine />
              <Title>瀏覽精選作品</Title>
              <TitleLine />
            </TitleContainer>
            <WorksCarousel
              slidesToShow={3}
              slidesToScroll={1}
              autoplay
              responsive={[
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 2
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2
                  }
                }
              ]}
            >
              {featuredWorks.map((work) => (
                <Link
                  to={`/${work.stylistUsername}/portfolio/${work.work._id}`}
                  key={work.stylistId}
                >
                  <WorkCard
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <LazyImage
                      src={`${PHOTO_URL}${work.work.imageUrls[0]}`}
                      alt={work.stylistName}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        backgroundRepeat: 'no-repeat'
                      }}
                    />
                    <WorkOverlay>
                      <WorkTitle>{work.stylistName}</WorkTitle>
                    </WorkOverlay>
                  </WorkCard>
                </Link>
              ))}
            </WorksCarousel>
          </FeaturedWorksSection>
        </AnimatedSection>

        <AnimatedSection
          ref={(el) => (sectionRefs.current[5] = el)}
          initial='hidden'
          animate={controls}
          variants={sectionVariants}
          testimonial
        >
          <TitleContainer>
            <TitleLine />
            <Title>顧客都這麼說</Title>
            <TitleLine />
          </TitleContainer>
          <SubTitle>經市場調查，把顧客最真實的體驗分享給你</SubTitle>
          <TestimonialSlider />
        </AnimatedSection>

        {/* Blog Section */}
        <AnimatedSection
          ref={(el) => (sectionRefs.current[6] = el)}
          initial='hidden'
          animate={controls}
          variants={sectionVariants}
        >
          <BlogPage />
        </AnimatedSection>
      </Container>

      <StyledPopup
        visible={popupVisible}
        onMaskClick={handlePopupClose}
        position='bottom'
      >
        <PopupContent>
          <Header>選擇日期與時間</Header>
          <CalendarWrapper>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <CustomDateCalendar
                value={selectedDate}
                onChange={handleDateChange}
                views={['day']}
                dayOfWeekFormatter={formatWeekday}
                minDate={today}
                disablePast={true}
              />
            </LocalizationProvider>
          </CalendarWrapper>
          <Message>{message}</Message>
          <TimeSelectionWrapper>
            <TimeSelectionTitle>時間選擇 (可選)</TimeSelectionTitle>
            <TimeSelection>
              <TimeDropdown
                value={startTime}
                onChange={handleStartTimeChange}
                disabled={!selectedDate}
              >
                {generateTimeOptions()}
              </TimeDropdown>
              <TimeSeparator>到</TimeSeparator>
              <TimeDropdown
                value={endTime}
                onChange={handleEndTimeChange}
                disabled={!selectedDate || !startTime}
              >
                {generateTimeOptions()}
              </TimeDropdown>
            </TimeSelection>
          </TimeSelectionWrapper>
          <ButtonGroup>
            <StyledButton onClick={handlePopupClose}>取消返回</StyledButton>
            <StyledButton
              color='primary'
              onClick={handleSearchTime}
              disabled={!selectedDate}
            >
              查詢
            </StyledButton>
          </ButtonGroup>
        </PopupContent>
      </StyledPopup>
    </>
  )
}

export default HomePage
