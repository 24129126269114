import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { Tabs, Button, Collapse } from 'antd'
import { HeartOutlined, HeartFilled } from '@ant-design/icons' // 添加這一行
import axios from 'axios'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { API_BASE_URL, PHOTO_URL } from '../utils/apiutils'
import { isStoreOpen, translateDayToChinese } from '../utils/utils'
import StoreService from '../components/StoreService'
import StorePortfolio from '../components/StorePortfolio'
import StoreStylists from '../components/StoreStylists'
import Reviews from '../components/Reviews'
import Loading from '../utils/Loading'
import { useAuth } from '../hooks/AuthContext'
import api from '../utils/api'
import { Toast } from 'antd-mobile'
import { FaFacebookSquare } from 'react-icons/fa'
import { FaLine, FaSquareInstagram } from 'react-icons/fa6'
import { TbCalendarCancel } from 'react-icons/tb'
import { Helmet } from 'react-helmet-async'
import {
  RiNotificationBadgeFill,
  RiTimerLine,
  RiHomeHeartLine
} from 'react-icons/ri'

// 定義淡入動畫
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  animation: ${fadeIn} 1s ease-in-out;
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  background: white;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const StoreDetails = styled.div`
  flex: 1;
  background: #f3f3f3;
  padding: 20px;
  margin-right: 20px;
  border-radius: 8px;
  transition: transform 0.3s;
  @media (max-width: 820px) {
    padding: 0px;
    margin-right: 0px;
  }
`

const StoreInfo = styled.div`
  text-align: center;
  h1 {
    font-size: 20px;
    color: #000;
    margin-bottom: 10px;
  }
  p {
    font-size: 14px;
    color: #333;
  }
  @media (max-width: 768px) {
    h1 {
      font-size: 18px;
    }
  }
`

const IconPadding = styled.div`
  margin-top: 10px;
  font-size: 14px;
`

const AddToFavoritesButton = styled(Button)`
  background-color: #d5728a;
  color: #fff;
  border: none;
  border-radius: 10px;
  width: 100%;
  margin-top: 20px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  &:hover {
    background-color: #bf5170;
  }
  display: flex;
  align-items: center;
  justify-content: center;

  .anticon {
    margin-right: 8px;
  }
`

const MenuSection = styled.div`
  flex: 2;
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: calc(100% - 340px);
  @media (max-width: 768px) {
    width: 100%;
    padding: 10px 0;
  }
`

const StyledSlider = styled(Slider)`
  margin-bottom: 20px;
  .slick-slide img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
    transition: transform 0.3s;
  }
  @media (max-width: 768px) {
    display: none;
  }
  .slick-prev:before,
  .slick-next:before {
    color: #d5728a;
  }
`

const SingleImageContainer = styled.div`
  margin-bottom: 20px;
  img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`

const StyledAutoSlider = styled(Slider)`
  display: none;
  @media (max-width: 768px) {
    display: block;
    margin: 0 0px 20px 0px;
    .slick-slide img {
      width: 100%;
      height: 200px;
      border-radius: 10px 10px 0px 0px;
      object-fit: cover;
    }
    .slick-prev:before,
    .slick-next:before {
      color: #d5728a; /* 可以根據需求調整箭頭顏色 */
    }
  }
`
const BusinessImage = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  object-position: top;
  border-radius: 5px;
  overflow: scroll;
  @media (max-width: 800px) {
    display: none;
  }
`
const Link = styled.a`
  color: #000;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`
const StyledDescription = styled.div`
  font-size: 14px;
  color: #333;
  margin-top: 20px;
  white-space: pre-line;
  text-align: justify;
`

const SingleImage = styled.img`
  width: 100%;
  height: 200px;
  border-radius: 10px 10px 0px 0px;
  object-fit: cover;
`

const { Panel } = Collapse

const AboutSection = styled.div`
  margin-top: 20px;
`

const StyledCollapse = styled(Collapse)`
  background-color: #f7f7f7;
  border-radius: 8px;
  overflow: hidden;

  .ant-collapse-item {
    border-bottom: 1px solid #e8e8e8;
  }

  .ant-collapse-header {
    font-weight: bold;
    color: #333;
  }

  .ant-collapse-content-box {
    padding: 16px;
    font-size: 14px;
    line-height: 1.6;
    color: #666;
  }
`

const SectionTitle = styled.h3`
  font-size: 16px;
  color: #333;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  svg {
    margin-right: 10px;
    font-size: 20px;
    color: #d5728a;
  }
`

const DayRow = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 5px;
  @media (max-width: 820px) {
    justify-content: space-between;
  }
`

const DayName = styled.span`
  font-weight: ${(props) => (props.isToday ? 'bold' : 'normal')};
  color: ${(props) => (props.isToday ? '#d5728a' : 'inherit')};
  @media (min-width: 820px) {
    margin-right: 50px;
  }
`

const Hours = styled.span`
  color: ${(props) => (props.isClosed ? '#999' : 'inherit')};
`

const StorePage = () => {
  const { username } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [store, setStore] = useState(null)
  const [activeTab, setActiveTab] = useState('1')
  const [isFavorite, setIsFavorite] = useState(false)
  const [reviewsData, setReviewsData] = useState([])
  const { user } = useAuth()

  useEffect(() => {
    const fetchStore = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/business_C/${username}`
        )
        setStore(response.data)
        setReviewsData(response.data.reviews)
      } catch (error) {
        console.error('Error fetching store data:', error)
      }
    }

    fetchStore()

    // 根據當前 URL 路徑設置活動標籤
    const path = location.pathname.split('/').pop()
    switch (path) {
      case 'services':
        setActiveTab('1')
        break
      case 'stylists':
        setActiveTab('2')
        break
      case 'portfolio':
        setActiveTab('3')
        break
      case 'about':
        setActiveTab('4')
        break
      case 'reviews':
        setActiveTab('5')
        break
      default:
        setActiveTab('1')
    }
  }, [username, location.pathname])

  useEffect(() => {
    const checkFavoriteStatus = async () => {
      if (user && store?._id) {
        try {
          const response = await api.get(
            `/api/user/${user.userId}/favorite-businesses`
          )
          const favorites = response.data.favoriteBusinesses
          setIsFavorite(favorites.some((fav) => fav._id === store._id))
        } catch (error) {
          console.error('檢查收藏狀態時出錯:', error)
        }
      }
    }

    if (store) {
      checkFavoriteStatus()
    }
  }, [user, store?._id])

  if (!store) {
    return <Loading />
  }

  const { isOpen, todayHours } = isStoreOpen(store.opening_hours)
  const currentDay = new Date().getDay()
  const dayNames = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ]
  const currentDayName = translateDayToChinese(dayNames[currentDay])

  const sliderSettings = {
    dots: false,
    speed: 500,
    slidesToShow: store.photos.length < 2 ? 1 : 2,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0
        }
      }
    ]
  }
  const sliderAutoSettings = {
    dots: false,
    infinite: true,
    fade: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 5000,
    cssEase: 'linear'
  }

  const renderOpeningHours = () => {
    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ]
    const chineseDays = [
      '星期日',
      '星期一',
      '星期二',
      '星期三',
      '星期四',
      '星期五',
      '星期六'
    ]
    const today = new Date().getDay()

    return days.map((day, index) => {
      const dayData = store.opening_hours[day]
      const isToday = index === today
      const isClosed = !dayData.enabled
      let hoursText = '休息'

      if (dayData.enabled && dayData.periods.length > 0) {
        const { start, end } = dayData.periods[0]
        hoursText = `${start} - ${end}`
      }

      return (
        <DayRow key={day}>
          <DayName isToday={isToday}>{chineseDays[index]}</DayName>
          <Hours isClosed={isClosed}>{hoursText}</Hours>
        </DayRow>
      )
    })
  }

  const handleAddToFavorites = async () => {
    if (!user) {
      Toast.show({
        content: '請先登錄以收藏店家',
        position: 'top'
      })
      return
    }

    try {
      if (isFavorite) {
        await api.delete(
          `${API_BASE_URL}/api/user/${user.userId}/favorite-businesses/${store._id}`
        )
        setIsFavorite(false)
        Toast.show({
          content: '已取消收藏店家',
          position: 'top'
        })
      } else {
        await api.post(
          `${API_BASE_URL}/api/user/${user.userId}/favorite-businesses/${store._id}`
        )
        setIsFavorite(true)
        Toast.show({
          content: '已成功收藏店家',
          position: 'top'
        })
      }
    } catch (error) {
      console.error('收藏店家時出錯:', error)
      Toast.show({
        content: '操作失敗，請稍後重試',
        position: 'top'
      })
    }
  }

  const handleTabChange = (key) => {
    setActiveTab(key)
    switch (key) {
      case '1':
        navigate(`/store/${username}/services`)
        break
      case '2':
        navigate(`/store/${username}/stylists`)
        break
      case '3':
        navigate(`/store/${username}/portfolio`)
        break
      case '4':
        navigate(`/store/${username}/about`)
        break
      case '5':
        navigate(`/store/${username}/reviews`)
        break
      default:
        navigate(`/store/${username}/services`)
    }
  }

  const tabItems = [
    {
      key: '1',
      label: '服務項目',
      children: <StoreService storeId={store?._id} />
    },
    {
      key: '2',
      label: '設計師',
      children: <StoreStylists storeId={store?._id} />
    },
    {
      key: '3',
      label: '作品集',
      children: <StorePortfolio businessId={store?._id} />
    },
    {
      key: '4',
      label: '關於店家',
      children: (
        <AboutSection>
          <SectionTitle>
            <RiHomeHeartLine />
            店家介紹
          </SectionTitle>
          <StyledDescription>{store.description}</StyledDescription>
          <hr
            style={{
              margin: '20px 0',
              border: 'none',
              borderTop: '1px solid #e8e8e8'
            }}
          />
          <SectionTitle>
            <RiTimerLine />
            營業時間
          </SectionTitle>
          <StyledDescription>{renderOpeningHours()}</StyledDescription>
          <hr
            style={{
              margin: '20px 0',
              border: 'none',
              borderTop: '1px solid #e8e8e8'
            }}
          />
          <SectionTitle>
            <RiNotificationBadgeFill />
            預約須知
          </SectionTitle>
          <StyledDescription>{store.bookingInstructions}</StyledDescription>
          <hr
            style={{
              margin: '20px 0',
              border: 'none',
              borderTop: '1px solid #e8e8e8'
            }}
          />
          <SectionTitle>
            <TbCalendarCancel />
            取消政策
          </SectionTitle>
          <StyledDescription>{store.cancellationPolicy}</StyledDescription>
        </AboutSection>
      )
    },
    {
      key: '5',
      label: '評價',
      children: <Reviews businessId={store?._id} />
    }
  ]

  return (
    <Container>
      <Helmet>
        <title>{`${store.name}｜線上預約 - 美感BeauteFeel`}</title>
        <meta
          name='description'
          content={`全台美容線上預約平台，不論美甲美容美髮美睫霧唇霧眉，輕鬆一鍵查詢並完成預約，找到最符合您需求的美容專家!`}
        />
      </Helmet>
      <Content>
        <StoreDetails>
          <StoreInfo>
            {store.photos.length > 1 ? (
              <StyledAutoSlider {...sliderAutoSettings}>
                {store.photos.map((photo, index) => (
                  <div key={index}>
                    <img
                      src={
                        photo.url
                          ? `${PHOTO_URL}${photo.url}`
                          : '/assets/business_photo.png'
                      }
                      alt={store.name}
                    />
                  </div>
                ))}
              </StyledAutoSlider>
            ) : (
              <SingleImage
                src={
                  store.photos[0]?.url
                    ? `${PHOTO_URL}${store.photos[0]?.url}`
                    : '/assets/business_photo.png'
                }
                alt={store.name}
              />
            )}
            <h1>{store.name}</h1>
            {store.photos.length > 1 && (
              <BusinessImage
                src={
                  store.photos && store.photos.length > 0
                    ? `${PHOTO_URL}${store.photos[0].url}`
                    : '/assets/business_photo.png'
                }
                alt={store.name}
              />
            )}
            <p>{store.address}</p>
            <p>聯繫方式：{store.phone}</p>
            <div>
              {store.instagram && (
                <Link href={store.instagram} target='_blank'>
                  <FaSquareInstagram
                    style={{
                      marginRight: '10px',
                      fontSize: '25px',
                      color: '#E1306C'
                    }}
                  />
                </Link>
              )}
              {store.line && (
                <Link href={store.line} target='_blank'>
                  <FaLine
                    style={{
                      marginRight: '10px',
                      fontSize: '25px',
                      color: '#00b900'
                    }}
                  />
                </Link>
              )}
              {store.facebook && (
                <Link href={store.facebook} target='_blank'>
                  <FaFacebookSquare
                    style={{
                      marginRight: '10px',
                      fontSize: '25px',
                      color: '#4267b2'
                    }}
                  />
                </Link>
              )}
            </div>

            <IconPadding>
              {isOpen ? (
                <span style={{ color: '#be3e5d' }}>營業中</span>
              ) : (
                <span style={{ color: '#be3e5d' }}>已打烊</span>
              )}{' '}
              · {currentDayName}{' '}
              {todayHours
                ? `${todayHours.start} – ${todayHours.end}`
                : 'Closed'}
            </IconPadding>
          </StoreInfo>
          <AddToFavoritesButton onClick={handleAddToFavorites}>
            {isFavorite ? (
              <>
                <HeartFilled />
                取消收藏店家
              </>
            ) : (
              <>
                <HeartOutlined />
                新增收藏店家
              </>
            )}
          </AddToFavoritesButton>
        </StoreDetails>
        <MenuSection>
          {store.photos.length > 1 ? (
            <StyledSlider {...sliderSettings}>
              {store.photos.map((photo, index) => (
                <div key={index}>
                  <img
                    src={`${PHOTO_URL}${photo.url}`}
                    alt={`商家照片${index + 1}`}
                  />
                </div>
              ))}
            </StyledSlider>
          ) : (
            <SingleImageContainer>
              <img
                src={
                  store.photos[0]?.url
                    ? `${PHOTO_URL}${store.photos[0]?.url}`
                    : '/assets/business_photo.png'
                }
                alt={store.name}
              />
            </SingleImageContainer>
          )}
          <Tabs
            activeKey={activeTab}
            onChange={handleTabChange}
            items={tabItems}
          />
        </MenuSection>
      </Content>
    </Container>
  )
}

export default StorePage
