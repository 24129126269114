import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { Avatar, Popover, Drawer, Badge, Divider } from 'antd'
import {
  UserOutlined,
  MenuOutlined,
  SettingOutlined,
  HeartOutlined,
  CalendarOutlined,
  SearchOutlined,
  HomeOutlined,
  ShopOutlined,
  BellOutlined,
  FileTextOutlined,
  LoginOutlined
} from '@ant-design/icons'
import { TiBusinessCard } from 'react-icons/ti'

import { useNavigate, useLocation, Link } from 'react-router-dom'
import { PHOTO_URL } from '../utils/apiutils'
import { useSocketMessage } from '../hooks/useSocket'
import NotificationComponent from './NotificationComponent'
import api from '../utils/api'
import { useAuth } from '../hooks/AuthContext'

const Container = styled.div`
  width: 100%;
  height: 60px;
  @media (max-width: 768px) {
    height: 60px;
  }
`

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  background-color: ${({ $isscrolled }) =>
    $isscrolled ? 'white' : 'transparent'};
  transition: all 0.3s ease-in-out;
  transform: translateY(${({ $hide }) => ($hide ? '-100%' : '0')});
  box-shadow: ${({ $isscrolled }) =>
    $isscrolled ? '0 2px 8px rgba(0, 0, 0, 0.1)' : 'none'};
`

const HeaderItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  box-sizing: border-box;
  background-color: white;

  @media (max-width: 768px) {
    height: 70px;
  }
`

const Logo = styled.div`
  display: flex;
  font-size: 22px;
  font-weight: bold;
  flex: 1;
  color: #d5728a;
  align-items: center;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 22px;
    margin-right: 10px;
  }
  @media (max-width: 425px) {
    font-size: 20px;
    margin-right: 0px;
  }
`

const AvatarContent = styled.div`
  &:hover {
    opacity: 0.8;
  }
  @media (max-width: 768px) {
    display: none;
  }
`

const MenuBar = styled.nav`
  display: flex;
  width: 100%;
  justify-content: space-around;
  background-color: #d5728a;
  /* border-radius: 0 0 20px 20px; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    position: fixed;
    z-index: 1000;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 70px;
    border-radius: 10px 10px 0 0;
    align-items: center;
  }
`
const MobileMenuIcon = styled(MenuOutlined)`
  font-size: 24px;
  color: #333;
  display: none;
  @media (max-width: 768px) {
    display: block;
    cursor: pointer;
    margin-right: 10px;
  }
`
const MenuItem = styled(Link)`
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  width: 100%;
  padding: 15px 20px;

  &:hover {
    background-color: #d45d7a;
    border-radius: 4px;
  }

  &.active {
    background-color: #d45d7a;
    border-radius: 4px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.account-link {
      display: flex;
    }

    &.stylists-link {
      display: none;
    }

    &.desktop-only {
      display: none;
    }
  }

  .menu-text {
    @media (max-width: 768px) {
      display: block;
      margin-top: 2px;
      font-size: 16px;
    }
  }

  .menu-icon {
    display: none;
    @media (max-width: 768px) {
      display: block;
      font-size: 20px;
    }
  }
`

const ProfileBox = styled.div`
  margin: 0 10px 10px 20px;
`

const ProfileName = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-align: left;
`

const ProfileEmail = styled.div`
  font-size: 14px;
  text-align: left;
`

const ProfileText = styled.div`
  display: flex;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  flex: 1;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`
const LoginButton = styled.button`
  padding: 5px 10px;
  color: #d5728a;
  font-size: 16px;
  background-color: #fff;
  border: 1px solid #d5728a;
  border-radius: 5px;
  cursor: pointer;
  @media (max-width: 768px) {
    padding: 10px 15px;
    font-size: 14px;
  }
`
const FooterContainer = styled.footer`
  background-color: #fff;
  border-top: 1px solid #eee;
`

const FooterContent = styled.div`
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
`

const Column = styled.div`
  flex: 1;
  min-width: 200px;
`

const FooterTitle = styled.h3`
  font-size: 14px;
  color: #333;
  font-weight: bold;
  margin-bottom: 10px;
  cursor: pointer;
`

const FooterSmallLink = styled.a`
  display: inline;
  font-size: 12px;
  color: #666;
  margin: 0 8px;
  text-decoration: none;

  &:hover {
    color: #d5728a;
    text-decoration: underline;
  }
  @media (max-width: 480px) {
    margin: 0 4px;
  }
`
const FooterLogo = styled.div`
  font-size: 18px;
  color: #d5728a;
  font-weight: bold;
  margin-top: 10px;
`

const FooterBottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 12px;
`
const FooterBottomLeft = styled.div`
  font-size: 12px;
  color: #aaa;
`
const FooterBottomRight = styled.div`
  margin-top: 20px;
  font-size: 12px;
  color: #000;
`

const DrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const DrawerMenu = styled.div`
  flex: 1;
`

const DrawerFooter = styled(FooterContainer)`
  margin-top: 20px; // 添加與上方菜單的間距
`

const DesktopNavItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;

  @media (max-width: 768px) {
    display: none;
  }
`

const NavLinks = styled.div`
  display: flex;
  align-items: center;
  margin-left: 50px;
`

const NotificationAndAvatarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  @media (max-width: 768px) {
    gap: 10px;
  }
`

const NavItem = styled(Link)`
  color: #333;
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  padding: 10px 15px;
  margin: 0 5px;
  border-radius: 4px;
  transition: background-color 0.3s;

  &:hover {
    color: #d5728a;
  }

  &.active {
    font-weight: bold;
    color: #d5728a;
  }
`

const LogoImage = styled.img`
  height: 30px;
  margin-right: 10px;

  @media (max-width: 768px) {
    height: 26px;
  }
`

const Navbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isscrolled, setisscrolled] = useState(false)
  const [hideHeader, setHideHeader] = useState(false)
  const [lastScrollY, setLastScrollY] = useState(0)
  const [scrollThreshold] = useState(100) // 新增：設置滾動閾值
  const [popoverVisible, setPopoverVisible] = useState(false)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [activeItem, setActiveItem] = useState('/')
  const navigate = useNavigate()
  const location = useLocation()
  const { user, logout } = useAuth()
  const [notifications, setNotifications] = useState([])
  const [unreadCount, setUnreadCount] = useState(0)
  const [notificationPopoverVisible, setNotificationPopoverVisible] =
    useState(false)
  const [loginType, setLoginType] = useState(null)

  useEffect(() => {
    if (user) {
      setIsLoggedIn(true)
      setLoginType(user.loginType)
    } else {
      setIsLoggedIn(false)
      setLoginType(null)
    }
    setPopoverVisible(false)
  }, [user])

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY

      if (currentScrollY > lastScrollY && currentScrollY > scrollThreshold) {
        setHideHeader(true)
      } else {
        setHideHeader(false)
      }

      if (currentScrollY > 0) {
        setisscrolled(true)
      } else {
        setisscrolled(false)
      }

      setLastScrollY(currentScrollY)
    }

    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [lastScrollY, scrollThreshold])

  useEffect(() => {
    setActiveItem(location.pathname)
  }, [location.pathname])

  const handleNavigate = (path) => {
    setPopoverVisible(false)
    setDrawerVisible(false)
    navigate(path)
    setActiveItem(path)
  }

  const handleLogout = async () => {
    try {
      await logout()
      setIsLoggedIn(false)
      setLoginType(null)
      setPopoverVisible(false)
      navigate('/')
      setDrawerVisible(false)
    } catch (error) {
      console.error('登出失敗:', error)
    }
  }

  const fetchNotifications = useCallback(async () => {
    if (user && user.userId) {
      try {
        const response = await api.get(`/api/notifications/all/${user.userId}`)
        setNotifications(response.data.notifications)
        setUnreadCount(
          response.data.notifications.filter((n) => !n.isRead).length
        )
      } catch (error) {
        console.error('獲取通知失敗:', error)
      }
    }
  }, [user])

  useEffect(() => {
    fetchNotifications()
  }, [fetchNotifications])

  useSocketMessage(
    useCallback(
      (message) => {
        if (message.type === 'booking_update') {
          fetchNotifications()
          console.log('收到新的通知:', message)
        }
      },
      [fetchNotifications]
    )
  )

  const handleNotificationClick = () => {
    if (window.innerWidth <= 480) {
      // 如果是手機版，直接導航到通知頁面
      navigate('/notifications')
    } else {
      // 桌面版顯示彈出框
      setNotificationPopoverVisible(true)
      fetchNotifications()
    }
  }

  const handleMarkAllAsRead = async () => {
    try {
      await api.post(`/api/notifications/mark-all-read/${user.userId}`)
      setUnreadCount(0)
      fetchNotifications()
    } catch (error) {
      console.error('標記所有通知為已讀失敗:', error)
    }
  }

  const menuItems = [
    { path: '/', icon: <HomeOutlined />, text: '首頁' },
    { path: '/services', icon: <SearchOutlined />, text: '探索所有服務' },
    {
      path: '/models',
      icon: <TiBusinessCard style={{ marginTop: '4px' }} />,
      text: '體驗模特兒'
    },
    { path: '/stylists', icon: <UserOutlined />, text: '所有設計師' },
    { path: '/articles', icon: <FileTextOutlined />, text: '文章專欄' }
  ]

  const renderMenuItems = () => (
    <>
      {menuItems.map((item) => (
        <ProfileText key={item.path} onClick={() => handleNavigate(item.path)}>
          {item.icon}
          <span style={{ marginLeft: '10px' }}>{item.text}</span>
        </ProfileText>
      ))}
    </>
  )

  const content = (
    <div>
      <div style={{ display: 'flex' }}>
        <Avatar
          size='small'
          src={
            loginType === 'line' || loginType === 'google'
              ? user?.avatar
              : user?.avatar
              ? `${PHOTO_URL}${user.avatar}`
              : null
          }
          icon={!user?.avatar ? <UserOutlined /> : null}
          style={{ cursor: 'pointer', marginTop: '10px' }}
          onClick={() => handleNavigate('/profile')}
        />
        <ProfileBox>
          <ProfileName>{user?.name || '訪客'}</ProfileName>
          <ProfileEmail>{user?.email || ''}</ProfileEmail>
        </ProfileBox>
      </div>
      {isLoggedIn && (
        <>
          <ProfileText onClick={() => handleNavigate('/account')}>
            <SettingOutlined style={{ marginRight: '10px' }} />
            個人資料設定
          </ProfileText>
          <ProfileText onClick={() => handleNavigate('/favorites/store')}>
            <HeartOutlined style={{ marginRight: '10px' }} /> 收藏清單
          </ProfileText>
          <ProfileText
            onClick={() =>
              handleNavigate(`/user-appointment-list/${user.username}`)
            }
          >
            <CalendarOutlined style={{ marginRight: '10px' }} /> 預約管理
          </ProfileText>
          {user.isModel && (
            <ProfileText onClick={() => handleNavigate(`/hiring`)}>
              <TiBusinessCard
                style={{
                  marginRight: '10px',
                  marginTop: '3px'
                }}
              />
              商家招聘
            </ProfileText>
          )}
          <ProfileText style={{ color: '#666' }} onClick={handleLogout}>
            登出
          </ProfileText>
        </>
      )}
    </div>
  )

  return (
    <>
      <Container>
        <Header $isscrolled={isscrolled} $hide={hideHeader}>
          <HeaderItem>
            <div style={{ display: 'flex' }}>
              <Logo onClick={() => handleNavigate('/')}>
                <LogoImage
                  src='/assets/logo/b-logo.png'
                  alt='BeauteFeel 美感'
                />
              </Logo>
            </div>
            <DesktopNavItems>
              <NavLinks>
                <NavItem
                  to='/#promotion'
                  className={
                    location.pathname === '/' && location.hash === '#promotion'
                      ? 'active'
                      : ''
                  }
                >
                  線上預約
                </NavItem>
                <NavItem
                  to='/businesses'
                  className={activeItem === '/businesses' ? 'active' : ''}
                >
                  精選店家
                </NavItem>
                <NavItem
                  to='/models'
                  className={activeItem === '/models' ? 'active' : ''}
                >
                  加入體驗模特兒
                </NavItem>
                <NavItem
                  to='/articles'
                  className={activeItem === '/articles' ? 'active' : ''}
                >
                  文章專欄
                </NavItem>
              </NavLinks>
              {!isLoggedIn && (
                <LoginButton onClick={() => handleNavigate('/login')}>
                  登入 / 註冊
                </LoginButton>
              )}
            </DesktopNavItems>
            <NotificationAndAvatarContainer>
              {isLoggedIn && (
                <>
                  <Popover
                    content={
                      <NotificationComponent
                        notifications={notifications}
                        onMarkAllAsRead={handleMarkAllAsRead}
                        fetchNotifications={fetchNotifications}
                      />
                    }
                    trigger='click'
                    placement='bottomRight'
                    open={notificationPopoverVisible}
                    onOpenChange={(visible) => {
                      if (window.innerWidth > 480) {
                        setNotificationPopoverVisible(visible)
                        if (visible) {
                          fetchNotifications()
                        }
                      }
                    }}
                  >
                    <Badge count={unreadCount} overflowCount={99}>
                      <BellOutlined
                        style={{ fontSize: '24px', cursor: 'pointer' }}
                        onClick={handleNotificationClick}
                      />
                    </Badge>
                  </Popover>
                  <Popover
                    content={content}
                    placement='bottomRight'
                    trigger='click'
                    open={popoverVisible}
                    onOpenChange={(visible) => setPopoverVisible(visible)}
                  >
                    <AvatarContent>
                      <Avatar
                        src={
                          loginType === 'line' || loginType === 'google'
                            ? user?.avatar
                            : user?.avatar
                            ? `${PHOTO_URL}${user.avatar}`
                            : null
                        }
                        size='large'
                        icon={!user?.avatar ? <UserOutlined /> : null}
                        style={{ cursor: 'pointer' }}
                      />
                    </AvatarContent>
                  </Popover>
                </>
              )}
              <MobileMenuIcon onClick={() => setDrawerVisible(true)} />
            </NotificationAndAvatarContainer>
          </HeaderItem>
        </Header>
      </Container>
      <MenuBar>
        <MenuItem to='/' className={activeItem === '/' ? 'active' : ''}>
          <span className='menu-icon'>
            <HomeOutlined />
          </span>
          <span className='menu-text'>首頁</span>
        </MenuItem>
        <MenuItem
          to='/services'
          className={activeItem === '/services' ? 'active' : ''}
        >
          <span className='menu-icon'>
            <SearchOutlined />
          </span>
          <span className='menu-text'>探索</span>
        </MenuItem>
        <MenuItem
          to='/businesses'
          className={activeItem === '/businesses' ? 'active' : ''}
        >
          <span className='menu-icon'>
            <ShopOutlined />
          </span>
          <span className='menu-text'>店家</span>
        </MenuItem>
        <MenuItem
          to='/stylists'
          className={`desktop-only ${
            activeItem === '/stylists' ? 'active' : ''
          }`}
        >
          <span className='menu-icon'>
            <UserOutlined />
          </span>
          <span className='menu-text'>設計師</span>
        </MenuItem>
        <MenuItem
          to='/dashboard'
          className={activeItem === '/account' ? 'active' : ''}
        >
          <span className='menu-icon'>
            <UserOutlined />
          </span>
          <span className='menu-text'>我的</span>
        </MenuItem>
      </MenuBar>
      <Drawer
        placement='right'
        onClose={() => setDrawerVisible(false)}
        width={260}
        open={drawerVisible}
      >
        <DrawerContent>
          <DrawerMenu>
            {renderMenuItems()}
            {isLoggedIn ? (
              <>
                <Divider />
                <ProfileText onClick={() => handleNavigate('/dashboard')}>
                  <SettingOutlined style={{ marginRight: '10px' }} />
                  我的賬戶
                </ProfileText>
                <ProfileText onClick={() => handleNavigate('/favorites/store')}>
                  <HeartOutlined style={{ marginRight: '10px' }} />
                  收藏清單
                </ProfileText>
                <ProfileText
                  onClick={() =>
                    handleNavigate(`/user-appointment-list/${user.username}`)
                  }
                >
                  <CalendarOutlined style={{ marginRight: '10px' }} /> 預約管理
                </ProfileText>
                {user.isModel && (
                  <ProfileText onClick={() => handleNavigate(`/hiring`)}>
                    <TiBusinessCard
                      style={{
                        marginRight: '10px',
                        marginTop: '3px'
                      }}
                    />
                    商家招聘
                  </ProfileText>
                )}
                <ProfileText style={{ color: '#666' }} onClick={handleLogout}>
                  登出
                </ProfileText>
              </>
            ) : (
              <>
                <Divider />
                <ProfileText onClick={() => handleNavigate('/login')}>
                  <LoginOutlined style={{ marginRight: '10px' }} />
                  登入 / 註冊
                </ProfileText>
              </>
            )}
          </DrawerMenu>
          <DrawerFooter>
            <FooterContent>
              <Column>
                <FooterLogo>
                  <LogoImage
                    src='/assets/logo/b-logo.png'
                    alt='BeauteFeel 美感'
                  />
                </FooterLogo>
              </Column>
              <Column>
                <FooterTitle
                  onClick={() =>
                    (window.location.href = 'https://joinus.beautefeel.com')
                  }
                >
                  商談合作
                </FooterTitle>
                <FooterTitle
                  onClick={() =>
                    (window.location.href = 'mailto:service@beautefeel.com')
                  }
                >
                  聯絡我們
                </FooterTitle>
              </Column>
            </FooterContent>
            <Divider />
            <FooterBottom>
              <FooterBottomLeft>© BeauteFeel 美感 2024</FooterBottomLeft>
              <FooterBottomRight>
                <FooterSmallLink href='/terms'>服務條款</FooterSmallLink>|
                <FooterSmallLink href='/copyright'>版權宣告</FooterSmallLink>|
                <FooterSmallLink href='/privacy'>隱私權政策</FooterSmallLink>
              </FooterBottomRight>
            </FooterBottom>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default Navbar
