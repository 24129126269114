import React, { useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '../hooks/AuthContext'
import Loading from '../utils/Loading'

const ProtectedRoute = ({ children }) => {
  const { user, checkUserStatus } = useAuth()
  const [isLoading, setIsLoading] = useState(true)
  const location = useLocation()

  useEffect(() => {
    const verifyUser = async () => {
      await checkUserStatus()
      setIsLoading(false)
    }
    verifyUser()
  }, [checkUserStatus])

  if (isLoading) {
    return <Loading />
  }

  if (!user) {
    return <Navigate to='/login' state={{ from: location.pathname }} replace />
  }

  // 檢查用戶郵箱是否以 "line" 開頭
  if (
    user.email.startsWith('line') &&
    location.pathname !== '/line-user-email-setup'
  ) {
    return <Navigate to='/line-user-email-setup' replace />
  }

  // 檢查用戶是否已驗證郵箱
  if (
    !user.isVerified &&
    location.pathname !== '/check-email' &&
    location.pathname !== '/verify-email'
  ) {
    return <Navigate to='/check-email' replace />
  }

  // 如果用戶已驗證，但嘗試訪問驗證相關頁面，重定向到首頁
  if (
    user.isVerified &&
    (location.pathname === '/check-email' ||
      location.pathname === '/verify-email')
  ) {
    return <Navigate to='/' replace />
  }

  return children
}

export default ProtectedRoute
