import React from 'react'
import { ConfigProvider } from 'antd'
import { HelmetProvider } from 'react-helmet-async'
import AppRouter from './router/AppRouter'
import './App.css'
import { AuthProvider } from './hooks/AuthContext'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import zh_TW from 'antd/es/locale/zh_TW'

dayjs.extend(utc)
dayjs.extend(timezone)

// 設置默認時區為台灣時間
dayjs.tz.setDefault('Asia/Taipei')

const App = () => {
  return (
    <HelmetProvider>
      <div className='App'>
        <AuthProvider>
          <ConfigProvider
            locale={zh_TW}
            theme={{
              token: {
                colorPrimary: '#d5728a',
                colorPrimaryBorder: '#F2B3C0'
              }
            }}
          >
            <AppRouter />
          </ConfigProvider>
        </AuthProvider>
      </div>
    </HelmetProvider>
  )
}

export default App
