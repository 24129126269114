import React from 'react'
import { useLocation } from 'react-router-dom'
import Breadcrumb from './Breadcrumb' // 假設您已有此組件

const pathsWithBreadcrumb = [
  '/services',
  '/stylists',
  '/category',
  '/store',
  '/businesses'
  // 添加其他需要麵包屑的路徑
]

const BreadcrumbWrapper = ({ children }) => {
  const location = useLocation()
  const shouldShowBreadcrumb = pathsWithBreadcrumb.some((path) =>
    location.pathname.startsWith(path)
  )

  return (
    <>
      {shouldShowBreadcrumb && <Breadcrumb />}
      {children}
    </>
  )
}

export default BreadcrumbWrapper
