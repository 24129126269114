import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import 'moment/locale/zh-cn'
import { API_BASE_URL, PHOTO_URL } from '../utils/apiutils'
import { Helmet } from 'react-helmet-async'

moment.locale('zh-cn') // 设置 moment 使用中文

// 添加分类映射
const categoryMap = {
  beauty: '美容保養',
  hair: '髮型風格',
  fashion: '時尚話題',
  art: '人文藝術',
  motivation: '心靈勵志',
  surgery: '醫美話題',
  body: '美體養生',
  nail: '美甲設計'
}

const Container = styled.div`
  padding: 20px;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

const TitleLine = styled.div`
  flex-grow: 1;
  height: 2px;
  background-color: #f9aca0;
`

const Title = styled.h1`
  font-size: 36px;
  color: #333;
  margin: 0 20px;
  white-space: nowrap;
`

const ArticlesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`

const ArticleCard = styled.div`
  flex: 1 1 calc(33.333% - 20px);
  border: 1px solid #eee;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`

const ArticleImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`

const ArticleContent = styled.div`
  padding: 15px;
`

const ArticleTitle = styled.h2`
  font-size: 18px;
  color: #d5728a;
  margin-bottom: 10px;
`

const ArticleDescription = styled.p`
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
`

const AuthorAndDate = styled.div`
  font-size: 12px;
  color: #999;
`

const ArticlePage = () => {
  const { category } = useParams()
  const [articles, setArticles] = useState([])

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/articles/category/${category}`
        )
        setArticles(response.data.data)
      } catch (error) {
        console.error('Error fetching articles:', error)
      }
    }

    fetchArticles()
  }, [category])

  return (
    <Container>
      <Helmet>
        <title>{`全台美業｜${
          categoryMap[category] || category
        }文章專欄 - 線上預約平台｜美感BeauteFeel`}</title>
        <meta
          name='description'
          content={`全台美容線上預約平台，不論美甲美容美髮美睫霧唇霧眉，輕鬆一鍵查詢並完成預約，找到最符合您需求的美容專家!`}
        />
      </Helmet>
      <TitleContainer>
        <TitleLine />
        <Title>{categoryMap[category] || category}</Title>
        <TitleLine />
      </TitleContainer>
      <ArticlesContainer>
        {articles.map((article) => (
          <ArticleCard
            key={article._id}
            onClick={() => (window.location.href = `/articles/${article._id}`)}
          >
            <ArticleImage
              src={`${PHOTO_URL}${article.image}`}
              alt={article.title}
            />
            <ArticleContent>
              <ArticleTitle>{article.title}</ArticleTitle>
              <ArticleDescription
                dangerouslySetInnerHTML={{
                  __html:
                    article.content.length > 100
                      ? article.content.substring(0, 100) + '...'
                      : article.content
                }}
              />
              <AuthorAndDate>
                作者{' '}
                <span style={{ color: '#1a73e8', cursor: 'pointer' }}>
                  {article.username}
                </span>{' '}
                {moment(article.createdAt).format('LL')}
              </AuthorAndDate>
            </ArticleContent>
          </ArticleCard>
        ))}
      </ArticlesContainer>
    </Container>
  )
}

export default ArticlePage
