import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import moment from 'moment-timezone'
import 'moment-timezone'
import { Button, Empty } from 'antd'
import axios from 'axios'
import { LocalizationProvider, DateCalendar } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { API_BASE_URL, PHOTO_URL } from '../utils/apiutils'
import Loading from '../utils/Loading'
import { MdOutlineAccessTime } from 'react-icons/md'
import { CalendarOutlined } from '@ant-design/icons'
moment.tz.setDefault('Asia/Taipei')

const Container = styled.div`
  height: 100%;
`
const LeftPane = styled.div`
  display: flex;
  flex: 2;
  justify-content: space-around;
  border-right: 1px solid #eee;
  @media (max-width: 820px) {
    border-right: none;
    display: block;
    padding: 0 10px;
  }
`

const SectionTitle = styled.div`
  font-size: 20px;
  color: #d5728a;
  font-weight: 600;
  margin-bottom: 20px;
  border-radius: 5px;
  @media (max-width: 820px) {
    background-color: #f6f6f6;
    padding: 10px;
  }
`

const TimeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  margin-top: 20px;

  @media (max-width: 820px) {
    grid-template-columns: repeat(5, 1fr); // 改為每行3個按鈕
    gap: 8px; // 減小間距
  }
`

const TimeButton = styled.button`
  background-color: ${(props) => (props.selected ? '#d5728a' : '#fff')};
  color: ${(props) => (props.selected ? '#fff' : '#000')};
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => (props.selected ? '#bf5170' : '#f0f0f0')};
  }

  @media (max-width: 820px) {
    padding: 12px 8px;
    font-size: 14px;
  }
`
const NoneSpace = styled.div`
  display: flex;
  margin-top: 30px;
  font-size: 14px;
  justify-content: center;
  align-items: center;
`
const TimeArrow = styled.div`
  cursor: pointer;
  font-size: 20px;
  margin: 0 10px;
  color: #666;
  user-select: none;
  display: ${(props) => (props.$hide ? 'none' : 'block')};
`

const Dots = styled.div`
  display: ${(props) => (props.$hide ? 'none' : 'flex')};
  justify-content: center;
  margin-top: 10px;
`

const Dot = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${(props) => (props.$active ? '#d5728a' : '#ddd')};
  border-radius: 50%;
  margin: 0 3px;
`
const ScrollContainer = styled.div`
  margin: 20px 0; // 移除左右margin
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: wrap;
  overflow: hidden;

  @media (max-width: 820px) {
    margin: 10px 0; // 進一步減少上下margin
  }
`
const CustomDateCalendar = styled(DateCalendar)`
  && {
    .MuiPickersDay-root {
      &:hover {
        background-color: #f2b3c0;
      }
    }

    .Mui-selected {
      background-color: #d5728a;

      &:hover {
        background-color: #f2b3c0;
      }
    }
    .MuiPickersDay-root.Mui-selected {
      background-color: #d5728a;
    }

    @media (max-width: 820px) {
      width: 100%; // 讓日曆佔滿寬度
      .MuiPickersCalendarHeader-root {
        margin-top: 0;
        padding-left: 0;
        padding-right: 0;
      }
      .MuiDayCalendar-header,
      .MuiDayCalendar-weekContainer {
        justify-content: space-between;
        padding: 0;
      }
      .MuiTypography-root {
        font-size: 15px;
      }
      .MuiPickersDay-root {
        font-size: 16px;
      }
    }
  }
`
const NextButton = styled(Button)`
  margin: 20px;
  font-size: 16px;
  height: 40px;
  padding: 0 30px;
`

const BookingStepTwo = ({
  selectedDate,
  setSelectedDate,
  selectedTime,
  setSelectedTime,
  nextStep,
  prevStep,
  storeId,
  storeUsername,
  staffId,
  product
}) => {
  const [availableTimes, setAvailableTimes] = useState([])
  const [currentSlide, setCurrentSlide] = useState(0)
  const [business, setBusiness] = useState(null)
  const [dayOffs, setDayOffs] = useState([])
  const [staffDayOffs, setStaffDayOffs] = useState([])
  const [disabledDays, setDisabledDays] = useState([])
  const [employeeDayOffs, setEmployeeDayOffs] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isTimeLoading, setIsTimeLoading] = useState(true)

  // 在组件顶部添加这个映射
  const dayOfWeekMap = {
    星期日: 'Sunday',
    星期一: 'Monday',
    星期二: 'Tuesday',
    星期三: 'Wednesday',
    星期四: 'Thursday',
    星期五: 'Friday',
    星期六: 'Saturday'
  }

  useEffect(() => {
    setIsLoading(true)
    if (!staffId) {
      axios
        .get(`${API_BASE_URL}/api/business_C/disable-date-no-select`, {
          params: {
            businessId: storeId,
            productId: product._id,
            timezone: 'Asia/Taipei'
          }
        })
        .then((response) => {
          setDisabledDays(response.data.disabledDays || [])
          setDayOffs(response.data.businessDayOffs || [])
          setEmployeeDayOffs(response.data.employeeDayOffs || [])
        })
        .catch((error) =>
          console.error('Error fetching disabled dates:', error)
        )
        .finally(() => setIsLoading(false))
    } else {
      // 添加 staffId 相关的逻辑
      axios
        .get(`${API_BASE_URL}/api/business_C/disabled-dates`, {
          params: {
            businessId: storeId,
            employeeId: staffId,
            productId: product._id
          }
        })
        .then((response) => {
          setDisabledDays(response.data.disabledDays || [])
          setDayOffs(response.data.businessDayOffs || [])
          setStaffDayOffs(response.data.employeeDayOffs || [])
        })
        .catch((error) =>
          console.error('Error fetching disabled dates:', error)
        )
        .finally(() => setIsLoading(false))
    }
  }, [storeId, product, staffId]) // 添加 staffId 到依赖数组

  useEffect(() => {
    setIsLoading(true)
    setIsTimeLoading(true)
    axios
      .get(`${API_BASE_URL}/api/business_C/${storeUsername}`)
      .then((response) => {
        setBusiness(response.data)
        if (staffId) {
          axios
            .get(`${API_BASE_URL}/api/business_C/${storeId}/${staffId}`, {
              params: {
                date: selectedDate
                  ? selectedDate.tz('Asia/Taipei').format('YYYY-MM-DD')
                  : null,
                productId: product ? product._id : null,
                timezone: 'Asia/Taipei'
              }
            })
            .then((res) => {
              setAvailableTimes(res.data || [])
              setIsTimeLoading(false)
            })
            .catch((error) =>
              console.error('Error fetching staff details:', error)
            )
        } else {
          axios
            .get(
              `${API_BASE_URL}/api/business_C/${storeId}/stylists/serviceTime`,
              {
                params: {
                  date: selectedDate
                    ? selectedDate.tz('Asia/Taipei').format('YYYY-MM-DD')
                    : null,
                  productId: product ? product._id : null,
                  timezone: 'Asia/Taipei'
                }
              }
            )
            .then((response) => {
              setAvailableTimes(response.data || [])
              setIsTimeLoading(false)
            })
            .catch((error) =>
              console.error('Error fetching available times:', error)
            )
        }
      })
      .catch((error) => {
        console.error('Error fetching business details:', error)
      })
      .finally(() => setIsLoading(false))
  }, [storeId, staffId, selectedDate, product])

  const getMarkedDates = useCallback(() => {
    const maxBookingDates =
      business?.appointmentSettings?.advanceBookingDays || 100
    const maxBookingDate = moment().add(maxBookingDates, 'days')

    const markedDates = {}

    if (selectedDate) {
      markedDates[selectedDate.format('YYYY-MM-DD')] = {
        selected: true,
        selectedColor: '#d5728a'
      }
    }

    if (dayOffs.length) {
      dayOffs.forEach((dayOff) => {
        const start = moment(dayOff.start)
        const end = moment(dayOff.end)
        while (start <= end) {
          const dateString = start.format('YYYY-MM-DD')
          markedDates[dateString] = {
            disabled: true,
            disableTouchEvent: true
          }
          start.add(1, 'day')
        }
      })
    }

    if (staffDayOffs.length) {
      staffDayOffs.forEach((dayOff) => {
        const start = moment(dayOff.start)
        const end = moment(dayOff.end)

        while (start <= end) {
          const dateString = start.format('YYYY-MM-DD')
          markedDates[dateString] = {
            disabled: true,
            disableTouchEvent: true
          }

          start.add(1, 'day')
        }
      })
    }

    if (disabledDays.length) {
      disabledDays.forEach((day) => {
        for (let i = 0; i < maxBookingDates; i++) {
          const date = moment().startOf('week').add(i, 'weeks').day(day)
          const dateString = date.format('YYYY-MM-DD')
          if (date.isBefore(maxBookingDate)) {
            markedDates[dateString] = {
              disabled: true,
              disableTouchEvent: true
            }
          }
        }
      })
    }

    if (employeeDayOffs.length) {
      employeeDayOffs.forEach((dayOff) => {
        const start = moment(dayOff.start)
        const end = moment(dayOff.end)

        while (start <= end) {
          const dateString = start.format('YYYY-MM-DD')
          markedDates[dateString] = {
            disabled: true,
            disableTouchEvent: true
          }

          start.add(1, 'day')
        }
      })
    }

    return markedDates
  }, [
    business,
    dayOffs,
    staffDayOffs,
    disabledDays,
    employeeDayOffs,
    selectedDate
  ])

  const handleDateChange = (date) => {
    setIsTimeLoading(true)
    const selectedDateInTaipei = moment(date).tz('Asia/Taipei')
    setSelectedDate(selectedDateInTaipei)
    setSelectedTime(null)

    axios
      .get(
        staffId
          ? `${API_BASE_URL}/api/business_C/${storeId}/${staffId}`
          : `${API_BASE_URL}/api/business_C/${storeId}/stylists/serviceTime`,
        {
          params: {
            date: selectedDateInTaipei.format('YYYY-MM-DD'),
            productId: product ? product._id : null,
            timezone: 'Asia/Taipei'
          }
        }
      )
      .then((response) => {
        // 过滤掉早于当前时间的时间段
        const currentTime = moment().tz('Asia/Taipei')
        const filteredTimes = response.data.filter((time) => {
          const timeInTaipei = moment.tz(
            `${selectedDateInTaipei.format('YYYY-MM-DD')} ${time}`,
            'YYYY-MM-DD HH:mm',
            'Asia/Taipei'
          )
          return timeInTaipei.isAfter(currentTime)
        })
        setAvailableTimes(filteredTimes)
      })
      .catch((error) => console.error('Error fetching available times:', error))
      .finally(() => setIsTimeLoading(false))
  }

  const handleTimeClick = (time) => {
    setSelectedTime(time)
  }

  const timesPerPage = 30
  const totalSlides = Math.ceil((availableTimes?.length || 0) / timesPerPage)

  const handleNextSlide = () => {
    if (currentSlide < totalSlides - 1) {
      setCurrentSlide(currentSlide + 1)
    }
  }

  const handlePrevSlide = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1)
    }
  }

  // 添加一个新的函数来过滤当前时间之后的时间段
  const filterAvailableTimes = (times) => {
    const currentTime = moment().tz('Asia/Taipei')
    return times.filter((time) => {
      const timeInTaipei = moment.tz(
        `${selectedDate.format('YYYY-MM-DD')} ${time}`,
        'YYYY-MM-DD HH:mm',
        'Asia/Taipei'
      )
      return timeInTaipei.isAfter(currentTime)
    })
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <Container>
      <LeftPane>
        <div style={{ display: 'inline', marginTop: 20 }}>
          <SectionTitle>
            <CalendarOutlined style={{ marginRight: 5, fontSize: 20 }} />
            選擇日期
          </SectionTitle>
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            dateLibInstance={moment}
          >
            <CustomDateCalendar
              value={selectedDate}
              onChange={handleDateChange}
              dayOfWeekFormatter={(weekday) => `${weekday.format('dd')}`}
              shouldDisableDate={(date) => {
                const dayString = moment(date).format('YYYY-MM-DD')
                const dayOfWeek = moment(date).format('dddd')
                const chineseDayOfWeek = moment(date)
                  .locale('zh-cn')
                  .format('dddd')
                const englishDayOfWeek =
                  dayOfWeekMap[chineseDayOfWeek] || dayOfWeek
                const markedDates = getMarkedDates()
                const isBeforeToday = moment(date).isBefore(moment(), 'day')
                const isAfterMaxBooking = moment(date).isAfter(
                  moment().add(
                    business?.appointmentSettings?.advanceBookingDays || 100,
                    'days'
                  )
                )
                const isMarkedDisabled = !!markedDates[dayString]?.disabled
                const isDisabledDay = disabledDays.includes(englishDayOfWeek)

                const isDisabled =
                  isBeforeToday ||
                  isAfterMaxBooking ||
                  isMarkedDisabled ||
                  isDisabledDay

                return isDisabled
              }}
            />
          </LocalizationProvider>
        </div>
        <div style={{ display: 'inline', marginTop: 20 }}>
          <SectionTitle>
            <MdOutlineAccessTime style={{ marginRight: 5, fontSize: 20 }} />
            選擇時間
          </SectionTitle>
          {isTimeLoading ? (
            <Loading />
          ) : (
            <ScrollContainer>
              <TimeArrow
                onClick={handlePrevSlide}
                $hide={(availableTimes?.length || 0) <= timesPerPage}
              >
                <LeftOutlined style={{ fontSize: 14 }} />
              </TimeArrow>
              {isTimeLoading ? (
                <Loading />
              ) : availableTimes?.length > 0 ? (
                <TimeGrid>
                  {filterAvailableTimes(availableTimes)
                    .slice(
                      currentSlide * timesPerPage,
                      (currentSlide + 1) * timesPerPage
                    )
                    .map((time, index) => (
                      <TimeButton
                        key={index}
                        selected={time === selectedTime}
                        onClick={() => handleTimeClick(time)}
                      >
                        {time}
                      </TimeButton>
                    ))}
                </TimeGrid>
              ) : (
                <NoneSpace>
                  <Empty
                    image={`${PHOTO_URL}/public/assets/nodata.svg`}
                    imageStyle={{
                      height: 100
                    }}
                    description={'無可預約時間，請重新選擇'}
                  />
                </NoneSpace>
              )}
              <TimeArrow
                onClick={handleNextSlide}
                $hide={(availableTimes?.length || 0) <= timesPerPage}
              >
                <RightOutlined style={{ fontSize: 14 }} />
              </TimeArrow>
            </ScrollContainer>
          )}
          <Dots $hide={(availableTimes?.length || 0) <= timesPerPage}>
            {Array.from({ length: totalSlides }).map((_, index) => (
              <Dot key={index} $active={index === currentSlide} />
            ))}
          </Dots>
        </div>
      </LeftPane>
      <NextButton onClick={prevStep}>返回</NextButton>
      <NextButton
        type='primary'
        onClick={nextStep}
        disabled={!selectedDate || !selectedTime}
      >
        下一步
      </NextButton>
    </Container>
  )
}

export default BookingStepTwo
