import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { HomeOutlined } from '@ant-design/icons'

const pathNameMap = {
  home: '首頁',
  services: '所有服務',
  about: '關於',
  experience: '經歷',
  reviews: '評價',
  stylists: '所有設計師',
  portfolio: '作品集',
  mybookings: '我的預約',
  account: '我的帳戶',
  favorite: '收藏清單',
  booking: '預約',
  blog: '部落格',
  articles: '文章',
  category: '分類',
  body: '美體養生',
  hair: '髮型風格',
  beauty: '美容保養',
  fashion: '時尚話題',
  art: '人文藝術',
  motivation: '心靈勵志',
  surgery: '醫美話題',
  nail: '美甲',
  search: '搜索',
  store: '所有商家',
  notifications: '通知',
  privacy: '隱私權政策',
  terms: '服務條款',
  copyright: '版權宣告',
  'user-appointment-list': '預約管理',
  'stylist-profile': '造型師個人資料',
  hair_care: '美髮',
  beauty_salon: '美容',
  eyebrown: '紋繡',
  shaving: '除毛',
  makeup: '彩妝',
  eyelash: '美睫',
  spa: '按摩',
  notFound: '找不到頁面',
  businesses: '所有店家'
}

const BreadcrumbContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 30px 0 30px;
  font-size: 16px;
  flex-wrap: wrap;
`

const BreadcrumbLink = styled(Link)`
  color: #666;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

const Separator = styled.span`
  margin: 0 5px;
  color: #666;
`

const Breadcrumb = () => {
  const location = useLocation()
  const navigate = useNavigate()
  let pathnames = location.pathname.split('/').filter((x) => x)

  const handleBreadcrumbClick = (name, index) => {
    if (pathnames[0] === 'businesses') {
      // 商家列表頁面的導航邏輯
      const currentCategory = pathnames[1]
      const currentRegion = pathnames[2]

      switch (index) {
        case 0: // 點擊"所有商家"
          navigate('/businesses/all/all/1')
          break
        case 1: // 點擊分類
          navigate(`/businesses/${name}/all/1`)
          break
        case 2: // 點擊地區
          navigate(
            `/businesses/${currentCategory}/${encodeURIComponent(name)}/1`
          )
          break
      }
      return
    }

    if (pathnames[0] === 'store') {
      // 商家詳情頁面，只允許點擊"所有商家"
      if (index === 0) {
        navigate('/businesses/all/all/1')
      }
      return
    }

    if (pathnames[0] === 'services') {
      // '_'為跳過第一個元素
      const [_, category, city] = pathnames

      // 如果點擊的是分類名稱
      if (decodeURIComponent(name) === decodeURIComponent(category)) {
        navigate(`/services/${category}/all-city/all-district/1`)
        return
      }

      // 如果點擊的是城市名稱
      if (decodeURIComponent(name) === decodeURIComponent(city)) {
        navigate(`/services/${category}/${city}/all-district/1`)
        return
      }
    }

    // 其他情況使用默認導航
    const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`
    navigate(routeTo)
  }

  return (
    <BreadcrumbContainer>
      <BreadcrumbLink to='/'>
        <HomeOutlined /> 首頁
      </BreadcrumbLink>
      {pathnames.map((name, index) => {
        // 跳過分頁數字和特定關鍵字
        if (
          name === 'all' ||
          name === 'all-city' ||
          name === 'all-district' ||
          !isNaN(name)
        ) {
          return null
        }

        // 判斷是否為商家詳情頁
        const isStoreDetailPage =
          pathnames[0] === 'store' &&
          (pathnames.length > 3 ||
            ['services', 'stylists', 'portfolio', 'about', 'reviews'].includes(
              pathnames[1]
            ))

        // 判斷是否為最後一個項目或商家名稱
        const isLast = index === pathnames.length - 1
        const isStoreName = isStoreDetailPage && index === 1
        const decodedName = decodeURIComponent(name)
        const translatedName = pathNameMap[decodedName] || decodedName

        return (
          <React.Fragment key={name}>
            <Separator> {'>'} </Separator>
            {isLast || isStoreName ? (
              <span>{translatedName}</span>
            ) : (
              <BreadcrumbSpan
                onClick={() => handleBreadcrumbClick(name, index)}
              >
                {translatedName}
              </BreadcrumbSpan>
            )}
          </React.Fragment>
        )
      })}
    </BreadcrumbContainer>
  )
}

// 新增樣式組件
const BreadcrumbSpan = styled.span`
  color: #666;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export default Breadcrumb
