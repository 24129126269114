import React from 'react'
import styled from 'styled-components'
import { Divider } from 'antd'
import { useAuth } from '../hooks/AuthContext'

const FooterContainer = styled.footer`
  background-color: #fff;
  padding: 40px 20px;
  text-align: center;
  border-top: 1px solid #eee;
  @media (max-width: 768px) {
    padding: 0px 20px 40px 20px;
  }
`

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

const Column = styled.div`
  flex: 1;
  padding: 0 20px;
  min-width: 200px;

  @media (max-width: 768px) {
    padding: 10px 0;
  }
`

const Logo = styled.h2`
  font-size: 24px;
  color: #d5728a;
  font-weight: bold;
  margin-bottom: 10px;
`

const Description = styled.p`
  font-size: 14px;
  color: #333;
  margin-bottom: 20px;
`

const FooterTitle = styled.h3`
  font-size: 16px;
  color: #333;
  font-weight: bold;
  margin-bottom: 10px;
`

const FooterLink = styled.a`
  display: block;
  font-size: 14px;
  color: #666;
  margin-bottom: 8px;
  text-decoration: none;

  &:hover {
    color: #d5728a;
    text-decoration: underline;
  }
`
const FooterSmallLink = styled.a`
  display: inline;
  font-size: 12px;
  color: #666;
  margin: 0 8px;
  text-decoration: none;

  &:hover {
    color: #d5728a;
    text-decoration: underline;
  }
`

const ContactInfo = styled.p`
  font-size: 14px;
  color: #d5728a;
  margin-bottom: 8px;
`

const FooterBottom = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 100px;
  font-size: 12px;
  @media (max-width: 768px) {
    margin: 20px 0px;
  }
`
const FooterBottomLeft = styled.div`
  margin-top: 20px;
  font-size: 12px;
  color: #aaa;
`
const FooterBottomRight = styled.div`
  margin-top: 20px;
  font-size: 12px;
  color: #000;
`

const Footer = () => {
  const { user } = useAuth()
  return (
    <FooterContainer>
      <FooterContent>
        <Column>
          <Logo>
            <img
              src='/assets/logo/b-logo.png'
              alt='BeauteFeel 美感'
              style={{ height: '30px', marginRight: '10px' }}
            />
          </Logo>
          <Description>
            一站式美業預約平台
            <br />
            全天候24小時不間斷
            <br />
            美髮、美睫、美甲、美容、護膚、SPA
            <br />
            <div style={{ marginTop: '10px' }}>
              <a
                href='https://lin.ee/8nb2EEq'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  src='https://scdn.line-apps.com/n/line_add_friends/btn/zh-Hant.png'
                  alt='加入好友'
                  height='36'
                  border='0'
                />
              </a>
            </div>
          </Description>
        </Column>
        <Column>
          <FooterTitle>快速導覽</FooterTitle>
          <FooterLink href='/favorites/store'>我的收藏</FooterLink>
          <FooterLink href={`/user-appointment-list/${user?.username}`}>
            我的預約
          </FooterLink>
          <FooterLink href='/articles'>話題文章</FooterLink>
        </Column>
        <Column>
          <FooterTitle>商談合作</FooterTitle>
          <a href='https://joinus.beautefeel.com'>
            <ContactInfo>也想把店家列入網站，請點擊我加入推廣計畫</ContactInfo>
          </a>
        </Column>
      </FooterContent>
      <Divider />
      <FooterBottom>
        <FooterBottomLeft>© BeauteFeel 美感 2024</FooterBottomLeft>
        <FooterBottomRight>
          <FooterSmallLink href='/terms'>服務條款</FooterSmallLink>|
          <FooterSmallLink href='/copyright'>版權宣告</FooterSmallLink>|
          <FooterSmallLink href='/privacy'>隱私權政策</FooterSmallLink>
        </FooterBottomRight>
      </FooterBottom>
    </FooterContainer>
  )
}

export default Footer
