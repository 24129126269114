import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { API_BASE_URL, PHOTO_URL } from '../utils/apiutils'
import { useParams, useNavigate } from 'react-router-dom'
import 'moment/locale/zh-tw' // 導入中文語言包
import {
  UpOutlined,
  DownOutlined,
  CopyOutlined,
  LeftOutlined,
  RightOutlined
} from '@ant-design/icons'
import { message, Modal, Input, Tabs, Empty, DatePicker, Steps } from 'antd'
import { useAuth } from '../hooks/AuthContext'
import api from '../utils/api'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import Loading from '../utils/Loading'
import { Helmet } from 'react-helmet-async'

dayjs.extend(utc)
dayjs.extend(timezone)

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`

const Note = styled.p`
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
  text-align: left;
`

const Card = styled.div`
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  text-align: left;

  @media (max-width: 768px) {
    padding: 12px;
  }
`

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
`

const Status = styled.div`
  height: 24px;
  background-color: ${(props) =>
    props.status === 'confirmed'
      ? '#4CAF50'
      : props.status === 'pending'
      ? '#FFA000'
      : props.status === 'completed'
      ? '#2196F3'
      : props.status === 'canceled'
      ? '#F44336'
      : '#9E9E9E'};
  color: white;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
`

const DateTimeContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #666;
`

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`

const MainInfo = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
`

const AdditionalInfo = styled.div`
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 12px;
  margin-top: 12px;
`

const InfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 14px;
  color: #666;
`

const InfoLabel = styled.span`
  font-weight: 500;
`

const InfoValue = styled.span`
  color: #333;
`

const ImageContainer = styled.div`
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 8px;
  margin-right: 16px;

  @media (max-width: 480px) {
    width: 60px;
    height: 60px;
  }
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Details = styled.div`
  flex: 1;
`

const SalonName = styled.h3`
  margin: 0 0 4px 0;
  color: #333;
  font-size: 16px;
  cursor: pointer;
`

const Description = styled.p`
  margin: 0 0 4px 0;
  font-size: 14px;
  color: #666;
`

const Service = styled(Description)`
  font-weight: 500;
  color: #333;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

const Button = styled.button`
  background-color: #f0f0f0;
  color: #333;
  border: none;
  padding: 8px 12px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #e0e0e0;
  }
`

const DepositTag = styled.span`
  background-color: ${(props) => (props.paid ? '#60755b' : '#FFA500')};
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  margin-left: 10px;
`

const DepositInfo = styled.div`
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  font-size: 16px;
  background-color: #f0f0f0;
  border-radius: 4px;
`

const CopyIcon = styled(CopyOutlined)`
  margin-left: 5px;
  font-size: 20px;
  cursor: pointer;
  color: #1890ff;
`

const DepositButton = styled(Button)`
  margin-top: 10px;
  color: #fff;
  background-color: #666;
  &:hover {
    background-color: #333;
  }
`

const TimeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
`

const TimeButton = styled.button`
  background-color: ${(props) => (props.selected ? '#d5728a' : '#fff')};
  color: ${(props) => (props.selected ? '#fff' : '#000')};
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => (props.selected ? '#bf5170' : '#f0f0f0')};
  }
`

const ScrollContainer = styled.div`
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
`

const TimeArrow = styled.div`
  cursor: pointer;
  font-size: 20px;
  margin: 0 10px;
  color: #666;
  user-select: none;
`

const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const DateMessage = styled.div`
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  color: ${(props) => (props.hasAvailableTimes ? '#52c41a' : '#faad14')};
  background-color: ${(props) =>
    props.hasAvailableTimes ? '#f6ffed' : '#fffbe6'};
  border: 1px solid
    ${(props) => (props.hasAvailableTimes ? '#b7eb8f' : '#ffe58f')};
`

const StyledButton = styled(Button)`
  margin-top: 20px;
  margin-right: 10px;
  width: 100%;
  background-color: ${(props) => (props.disabled ? '#f5f5f5' : '#d45d7a')};
  color: ${(props) => (props.disabled ? '#d9d9d9' : '#fff')};
  border-color: ${(props) => (props.disabled ? '#d9d9d9' : '#d45d7a')};
  &:hover,
  &:focus {
    background-color: ${(props) => (props.disabled ? '#f5f5f5' : '#bf5170')};
    color: ${(props) => (props.disabled ? '#d9d9d9' : '#fff')};
    border-color: ${(props) => (props.disabled ? '#d9d9d9' : '#bf5170')};
  }
`

const AppointmentCard = ({
  appointment,
  onDepositUpdate,
  onAppointmentUpdate
}) => {
  const [showDepositInfo, setShowDepositInfo] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [depositBankNumber, setDepositBankNumber] = useState('')
  const [isRescheduleModalVisible, setIsRescheduleModalVisible] =
    useState(false)
  const [newDate, setNewDate] = useState(null)
  const [newTime, setNewTime] = useState(null)
  const [availableTimes, setAvailableTimes] = useState([])
  const [currentSlide, setCurrentSlide] = useState(0)
  const [isLoadingTimes, setIsLoadingTimes] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const [dateMessage, setDateMessage] = useState('')
  const [rescheduleError, setRescheduleError] = useState('')
  const [cancelError, setCancelError] = useState('')
  const navigate = useNavigate()
  const toggleDepositInfo = () => {
    setShowDepositInfo(!showDepositInfo)
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = async () => {
    if (!/^\d{5}$/.test(depositBankNumber)) {
      message.error('請輸入5位數字的匯款後五碼')
      return
    }

    try {
      const response = await api.patch(
        `/api/client/bookings/${appointment._id}/deposit`,
        { depositBankNumber }
      )
      message.success('匯款信息更新成功')
      setIsModalVisible(false)
      onDepositUpdate(response.data.booking)
    } catch (error) {
      message.error(error.response?.data?.error || '更新失敗，請稍後再試')
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }
  const handleWriteReview = (appointmentId) => {
    navigate(`/write-review/${appointmentId}`)
  }

  const renderDepositTag = () => {
    if (appointment.depositStatus === 'not_required') return null
    return (
      <DepositTag
        paid={appointment.depositStatus === 'paid'}
        onClick={appointment.depositStatus !== 'paid' ? showModal : undefined}
        style={
          appointment.depositStatus !== 'paid' ? { cursor: 'pointer' } : {}
        }
      >
        {appointment.depositStatus === 'paid' ? '已付訂金' : '未付訂金'}
      </DepositTag>
    )
  }

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      message.success('複製成功！')
    })
  }

  const renderDepositInfo = () => {
    if (appointment.depositStatus === 'not_required') return null
    return (
      <DepositInfo>
        <p>請按照以下資訊完成付款</p>
        <p>需付金額: ${appointment.depositSettings?.depositAmount}</p>
        <p>銀行代碼: {appointment.depositSettings?.bankCode}</p>
        <p>銀行名稱: {appointment.depositSettings?.bankName}</p>
        <p>
          銀行帳號: {appointment.depositSettings?.accountNumber}
          <CopyIcon
            onClick={() =>
              handleCopy(appointment.depositSettings?.accountNumber)
            }
          />
        </p>
        <p>
          付款期限:{' '}
          {appointment.depositDueDate
            ? dayjs(appointment.depositDueDate).format('YYYY年MM月DD日 HH:mm')
            : '無'}
        </p>

        {appointment.depositStatus !== 'paid' && (
          <DepositButton onClick={showModal}>輸入匯款後五碼</DepositButton>
        )}
      </DepositInfo>
    )
  }

  const showRescheduleModal = () => {
    setIsRescheduleModalVisible(true)
    // 打开modal时不调用API
  }

  const handleDateChange = async (date) => {
    setNewDate(date)
    setNewTime(null)
    setIsLoadingTimes(true)
    setDateMessage('')

    try {
      const selectedDate = dayjs(date).startOf('day')
      const response = await api.get(
        `${API_BASE_URL}/api/business_C/${appointment.businessId}/${appointment.stylistId}`,
        {
          params: {
            date: selectedDate.format('YYYY-MM-DD'),
            productId: appointment.productId,
            timezone: 'Asia/Taipei'
          }
        }
      )

      const currentTime = dayjs()
      const filteredTimes = response.data.filter((time) => {
        const appointmentTime = dayjs(
          `${selectedDate.format('YYYY-MM-DD')} ${time}`,
          'YYYY-MM-DD HH:mm'
        )
        return appointmentTime.isAfter(currentTime)
      })

      setAvailableTimes(filteredTimes)

      if (filteredTimes.length === 0) {
        setDateMessage('該日期沒有可預約時間，請選擇其他日期。')
      } else {
        setDateMessage(`該日期有 ${filteredTimes.length} 個可預約時段。`)
      }
    } catch (error) {
      setDateMessage('獲取可用時間失敗，請稍後再試。')
    } finally {
      setIsLoadingTimes(false)
    }
  }

  const handleReschedule = async () => {
    if (!newDate || !newTime) {
      message.error('請選擇新的日期和時間')
      return
    }

    try {
      setRescheduleError('') // 重置錯誤信息
      const response = await api.put(
        `/api/user/bookings/${appointment._id}/reschedule`,
        {
          newDate: newDate.format('YYYY-MM-DD'),
          newTime: newTime
        }
      )

      if (response.data.success) {
        message.success('預約修改成功')
        setIsRescheduleModalVisible(false)
        onAppointmentUpdate(response.data.booking)
      } else {
        setRescheduleError(response.data.error || '修改失敗，請稍後再試')
      }
    } catch (error) {
      setRescheduleError(error.response?.data?.error || '修改失敗，請稍後再試')
    }
  }

  const handleCancelAppointment = async () => {
    Modal.confirm({
      title: '確認取消預約',
      content: '您確定要取消這個預約嗎？',
      onOk: async () => {
        try {
          setCancelError('') // 重置錯誤信息
          const response = await api.post(
            `/api/user/bookings/${appointment._id}/cancel`
          )
          if (response.data.success) {
            message.success('預約已取消')
            onAppointmentUpdate({ ...appointment, status: 'canceled' })
          } else {
            setCancelError(response.data.error || '取消失敗，請稍後再試')
            Modal.error({
              title: '取消預約失敗',
              content: response.data.error || '取消失敗，請稍後再試'
            })
          }
        } catch (error) {
          const errorMessage =
            error.response?.data?.error || '取消失敗，請稍後再試'
          setCancelError(errorMessage)
          Modal.error({
            title: '取消預約失敗',
            content: errorMessage
          })
        }
      }
    })
  }

  const handleTimeClick = (time) => {
    setNewTime(time)
  }

  const timesPerPage = 8
  const totalSlides = Math.ceil(availableTimes.length / timesPerPage)

  const handleNextSlide = () => {
    if (currentSlide < totalSlides - 1) {
      setCurrentSlide(currentSlide + 1)
    }
  }

  const handlePrevSlide = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1)
    }
  }

  const renderCouponInfo = () => {
    if (appointment.appliedCoupon) {
      return (
        <>
          <InfoItem>
            <InfoLabel>優惠券代碼：</InfoLabel>
            <InfoValue>{appointment.appliedCoupon.code}</InfoValue>
          </InfoItem>
          <InfoItem>
            <InfoLabel>優惠券折扣：</InfoLabel>
            <InfoValue>{appointment.appliedCoupon.discount}</InfoValue>
          </InfoItem>
          {appointment.appliedCoupon.description && (
            <InfoItem>
              <InfoLabel>優惠券描述：</InfoLabel>
              <InfoValue>{appointment.appliedCoupon.description}</InfoValue>
            </InfoItem>
          )}
        </>
      )
    }
    return null
  }

  const handleNextStep = () => {
    if (currentStep === 0 && (!newDate || availableTimes.length === 0)) {
      message.warning('請選擇有可用時間的日期')
      return
    }
    if (currentStep === 1 && !newTime) {
      message.warning('請選擇時間')
      return
    }
    setCurrentStep(currentStep + 1)
  }

  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <DatePickerContainer>
            <DatePicker
              value={newDate}
              onChange={handleDateChange}
              style={{ width: '100%' }}
            />
            {dateMessage && (
              <DateMessage hasAvailableTimes={availableTimes.length > 0}>
                {dateMessage}
              </DateMessage>
            )}
          </DatePickerContainer>
        )
      case 1:
        return isLoadingTimes ? (
          <Loading />
        ) : availableTimes.length > 0 ? (
          <ScrollContainer>
            <TimeArrow onClick={handlePrevSlide}>
              <LeftOutlined />
            </TimeArrow>
            <TimeGrid>
              {availableTimes
                .slice(
                  currentSlide * timesPerPage,
                  (currentSlide + 1) * timesPerPage
                )
                .map((time, index) => (
                  <TimeButton
                    key={index}
                    selected={time === newTime}
                    onClick={() => handleTimeClick(time)}
                  >
                    {time}
                  </TimeButton>
                ))}
            </TimeGrid>
            <TimeArrow onClick={handleNextSlide}>
              <RightOutlined />
            </TimeArrow>
          </ScrollContainer>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description='該日期沒有可預約時間，請返回選擇其他日期。'
          />
        )
      case 2:
        return (
          <div>
            <p>確認新的預約時間：</p>
            <p>日期：{newDate?.format('YYYY-MM-DD')}</p>
            <p>時間：{newTime}</p>
            {rescheduleError && (
              <p style={{ color: 'red', marginTop: '10px' }}>
                {rescheduleError}
              </p>
            )}
          </div>
        )
      default:
        return null
    }
  }

  return (
    <Card>
      <CardHeader>
        <StatusContainer>
          <Status status={appointment.status}>
            {appointment.status === 'confirmed'
              ? '已確認'
              : appointment.status === 'pending'
              ? '待確認'
              : appointment.status === 'completed'
              ? '已完成'
              : appointment.status === 'canceled'
              ? '已取消'
              : appointment.status}
          </Status>
          {renderDepositTag()}
        </StatusContainer>
        <DateTimeContainer>
          <span>{appointment.date}</span>
          <span style={{ margin: '0 4px' }}>|</span>
          <span>{appointment.time}</span>
        </DateTimeContainer>
      </CardHeader>
      <CardBody>
        <MainInfo>
          <ImageContainer>
            <Image
              src={`${PHOTO_URL}${appointment.productImage}`}
              alt='預約紀錄'
            />
          </ImageContainer>
          <Details>
            <SalonName
              onClick={() => navigate(`/store/${appointment.businessUsername}`)}
            >
              {appointment.businessName}
            </SalonName>
            {appointment.businessAddress && (
              <InfoItem>
                <InfoValue>{appointment.businessAddress}</InfoValue>
              </InfoItem>
            )}
            <Description>{appointment.productName}</Description>
            <Service>服務人員：{appointment.stylistName}</Service>
          </Details>
        </MainInfo>
        <AdditionalInfo>
          <InfoItem>
            <InfoLabel>原價：</InfoLabel>
            <InfoValue>${appointment.originalPrice}</InfoValue>
          </InfoItem>
          {appointment.discountedPrice !== appointment.originalPrice && (
            <InfoItem>
              <InfoLabel>折扣後價格：</InfoLabel>
              <InfoValue>${appointment.discountedPrice}</InfoValue>
            </InfoItem>
          )}
          {renderCouponInfo()}
          <InfoItem>
            <InfoLabel>總金額：</InfoLabel>
            <InfoValue>${appointment.discountedPrice}</InfoValue>
          </InfoItem>
        </AdditionalInfo>
      </CardBody>
      <ButtonContainer>
        {appointment.status === 'pending' && (
          <>
            <Button onClick={handleCancelAppointment}>取消預約</Button>
            <Button onClick={showRescheduleModal}>修改預約</Button>
          </>
        )}
        {appointment.status === 'completed' && (
          <Button
            onClick={() =>
              !appointment.isRated && handleWriteReview(appointment._id)
            }
            disabled={appointment.isRated}
          >
            {appointment.isRated ? '已評價' : '寫評價'}
          </Button>
        )}
        {appointment.depositStatus !== 'not_required' &&
          appointment.status !== 'canceled' && (
            <Button onClick={toggleDepositInfo}>
              {showDepositInfo ? '收起' : '展開'}定金資訊
              {showDepositInfo ? (
                <UpOutlined style={{ marginLeft: '4px' }} />
              ) : (
                <DownOutlined style={{ marginLeft: '4px' }} />
              )}
            </Button>
          )}
        {showDepositInfo && renderDepositInfo()}
      </ButtonContainer>
      <Modal
        title='輸入匯款後五碼'
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Input
          placeholder='請輸入5位數字的匯款後五碼'
          value={depositBankNumber}
          onChange={(e) => setDepositBankNumber(e.target.value)}
          maxLength={5}
        />
      </Modal>
      <Modal
        title='修改預約'
        open={isRescheduleModalVisible}
        onCancel={() => {
          setIsRescheduleModalVisible(false)
          setRescheduleError('') // 關閉模態框時重置錯誤信息
        }}
        footer={null}
        width={600}
      >
        <Steps current={currentStep}>
          <Steps.Step title='選擇日期' />
          <Steps.Step title='選擇時間' />
          <Steps.Step title='確認修改' />
        </Steps>
        <div style={{ marginTop: 24, marginBottom: 24 }}>
          {renderStepContent()}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {currentStep > 0 && (
            <StyledButton onClick={() => setCurrentStep(currentStep - 1)}>
              上一步
            </StyledButton>
          )}
          {currentStep < 2 ? (
            <StyledButton
              onClick={handleNextStep}
              disabled={
                (currentStep === 0 &&
                  (!newDate || availableTimes.length === 0)) ||
                (currentStep === 1 && !newTime)
              }
            >
              下一步
            </StyledButton>
          ) : (
            <StyledButton
              onClick={handleReschedule}
              disabled={!!rescheduleError}
            >
              確認修改
            </StyledButton>
          )}
        </div>
      </Modal>
      {cancelError && <ErrorMessage>{cancelError}</ErrorMessage>}
    </Card>
  )
}

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`

const LoadingSpinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #d45d7a;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const FadeIn = styled.div`
  animation: fadeIn 0.5s ease-in;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 20px;
  cursor: pointer;
`

const TitleBar = styled.div`
  width: 5px;
  height: 18px;
  margin-right: 10px;
  background-color: #d5728a;
`
const Title = styled.div`
  font-size: 18px;
  color: #333;
  font-weight: bold;
`

const PageButton = styled.button`
  background-color: ${(props) => (props.active ? '#d45d7a' : '#fff')};
  color: ${(props) => (props.active ? '#fff' : '#d45d7a')};
  border: 1px solid #d45d7a;
  padding: 5px 10px;
  margin: 0 5px;
  border-radius: 4px;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

const { TabPane } = Tabs

const UserAppointmentList = () => {
  const { username } = useParams()
  const [appointments, setAppointments] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const { user } = useAuth()
  const [activeTab, setActiveTab] = useState('all')
  const navigate = useNavigate()

  useEffect(() => {
    const fetchAppointments = async () => {
      if (!user || !user.userId) {
        return
      }
      try {
        setLoading(true)
        const response = await api.get(
          `/api/client/bookings/user/${user.userId}?page=${currentPage}`
        )
        setAppointments(response.data.bookings)
        setTotalPages(response.data.totalPages)
      } catch (err) {
        console.error('獲取預約數據時出錯:', err.response || err)
        setError(
          '獲取預約數據失敗: ' +
            (err.response?.data?.message || err.message || '未知錯誤')
        )
      } finally {
        setLoading(false)
      }
    }

    fetchAppointments()
  }, [currentPage, user])

  const filterAppointments = (appointments) => {
    const now = dayjs()
    return appointments.filter((appointment) => {
      const appointmentDateTime = dayjs(
        `${appointment.date} ${appointment.time}`,
        'YYYY-MM-DD HH:mm'
      )
      switch (activeTab) {
        case 'upcoming':
          return (
            (appointment.status === 'pending' ||
              appointment.status === 'confirmed') &&
            appointmentDateTime.isAfter(now)
          )
        case 'completed':
          return (
            appointment.status === 'completed' ||
            (appointmentDateTime.isBefore(now) &&
              appointment.status !== 'canceled')
          )
        case 'canceled':
          return appointment.status === 'canceled'
        default:
          return true
      }
    })
  }

  const handleTabChange = (key) => {
    setActiveTab(key)
    navigate(`/user-appointment-list/${username}/${key}/${currentPage}`)
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage)
    navigate(`/user-appointment-list/${username}/${activeTab}/${newPage}`)
  }

  const handleDepositUpdate = (updatedBooking) => {
    setAppointments(
      appointments.map((appointment) =>
        appointment._id === updatedBooking._id ? updatedBooking : appointment
      )
    )
  }

  const handleAppointmentUpdate = (updatedAppointment) => {
    setAppointments(
      appointments.map((appointment) =>
        appointment._id === updatedAppointment._id
          ? updatedAppointment
          : appointment
      )
    )
  }

  if (loading)
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    )

  if (error) return <Container>錯誤：{error}</Container>

  return (
    <Container>
      <Helmet>
        <title>{`預約管理 ｜ 線上即時預約平台 - 美感｜BeauteFeel`}</title>
        <meta
          name='description'
          content={`全台美容線上預約平台，不論美甲美容美髮美睫霧唇霧眉，輕鬆一鍵查詢並完成預約，找到最符合您需求的美容專家!`}
        />
      </Helmet>
      <TitleContainer>
        <TitleBar />
        <Title>預約管理</Title>
      </TitleContainer>
      <Note>※如預約狀態、取消等顯示有誤，請直接與沙龍聯繫。</Note>
      <Tabs activeKey={activeTab} onChange={handleTabChange}>
        <TabPane tab='所有' key='all' />
        <TabPane tab='即將到來' key='upcoming' />
        <TabPane tab='已完成' key='completed' />
        <TabPane tab='已取消' key='canceled' />
      </Tabs>
      <FadeIn>
        {filterAppointments(appointments).length > 0 ? (
          filterAppointments(appointments).map((appointment) => (
            <AppointmentCard
              key={appointment._id}
              appointment={appointment}
              onDepositUpdate={handleDepositUpdate}
              onAppointmentUpdate={handleAppointmentUpdate}
            />
          ))
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            style={{ marginTop: '50px' }}
            description='暫無預約數據'
          />
        )}
      </FadeIn>
      {filterAppointments(appointments).length > 0 && (
        <PaginationContainer>
          <PageButton
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            上一頁
          </PageButton>
          {[...Array(totalPages).keys()].map((page) => (
            <PageButton
              key={page + 1}
              onClick={() => handlePageChange(page + 1)}
              active={currentPage === page + 1}
            >
              {page + 1}
            </PageButton>
          ))}
          <PageButton
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            下一頁
          </PageButton>
        </PaginationContainer>
      )}
    </Container>
  )
}

// 新增的樣式組件
const ErrorMessage = styled.p`
  color: red;
  margin-top: 10px;
  font-size: 14px;
`

export default UserAppointmentList
