import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { PHOTO_URL } from '../utils/apiutils'
import { ArrowLeft, Search } from 'react-feather'
import { useNavigate, useParams } from 'react-router-dom'
import { motion } from 'framer-motion'
import { message } from 'antd'
import api from '../utils/api'
import { useAuth } from '../hooks/AuthContext'

const LeftPane = styled.div`
  flex: 2;
  padding: 20px;
  border-right: 1px solid #eee;
  @media (max-width: 820px) {
    border-right: none;
    padding: 0px;
  }
`

const EmployeeContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin: 20px 0;

  @media (min-width: 821px) {
    grid-template-columns: repeat(3, 1fr);
  }
`

const SectionTitle = styled.h2`
  color: #d5728a;
  margin-bottom: 20px;
`

const SearchInput = styled.input`
  flex: 1;
  border: none;
  padding: 10px;
  font-size: 16px;
  background: transparent;
  &:focus {
    outline: none;
  }
`

const SearchIcon = styled(Search)`
  color: #d5728a;
  margin-right: 10px;
`

const EmptyState = styled.div`
  text-align: center;
  color: #888;
  margin-top: 20px;
`

const EmployeeCard = styled(motion.div)`
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
`

const EmployeeAvatar = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
  cursor: pointer;
  background-color: #f6f6f6;

  @media (min-width: 768px) {
    height: 180px;
  }
`

const StylistInfo = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: space-between;
  height: 180px; // 添加固定高度

  @media (max-width: 480px) {
    height: 150px;
  }
`

const StylistName = styled.h3`
  font-size: 16px;
  color: #333;
  margin: 0 0 5px 0;

  @media (min-width: 768px) {
    font-size: 18px;
    margin-bottom: 8px;
  }
`

const StylistTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
`

const StylistTag = styled.span`
  background-color: #f8e1e7;
  color: #d5728a;
  font-size: 10px;
  padding: 2px 6px;
  border-radius: 10px;
  margin-right: 4px;
  margin-bottom: 4px;

  @media (min-width: 768px) {
    font-size: 12px;
    padding: 3px 8px;
  }
`

const StylistDescription = styled.p`
  font-size: 12px;
  color: #666;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    font-size: 14px;
  }
`
const SelectButton = styled.button`
  width: 100%;
  background-color: #d5728a;
  color: white;
  border: none;
  padding: 8px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 5px;
  margin-top: auto; // 將按鈕推到底部

  &:hover {
    background-color: #c4617a;
  }

  @media (min-width: 768px) {
    padding: 10px;
    font-size: 16px;
  }
`

const BackButton = styled.button`
  display: flex;
  padding: 5px 10px;
  align-items: center;
  background: none;
  color: #666;
  border: none;
  cursor: pointer;
  margin-bottom: 20px;
  font-size: 16px;
`

const DEFAULT_AVATAR = `/public/assets/default_avatar.png`
const DEFAULT_STYLIST = {
  _id: null,
  name: '不指定設計師',
  avatar: DEFAULT_AVATAR,
  businessName: '',
  tags: []
}

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #f2b3c0;
  border-radius: 25px;
  padding: 5px 15px;
  margin-bottom: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;

  &:focus-within {
    box-shadow: 0 2px 10px rgba(213, 114, 138, 0.2);
    border-color: #d5728a;
  }
`

// 新增一個函數來處理標籤
const getDisplayTags = (tags) => {
  return tags.slice(0, 3)
}

// 添加新的styled组件用于Modal
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 400px;
`

const ModalTitle = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`

const ModalText = styled.p`
  margin-bottom: 10px;
`

const ConfirmButton = styled.button`
  width: 100%;
  background-color: #d5728a;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
`

// 新增訪問次數判斷函數
const shouldShowDeposit = (depositSettings, visitCount) => {
  if (!depositSettings?.isEnabled) return false

  switch (depositSettings.depositCondition) {
    case 'all':
      return true
    case 'newCustomer':
      return visitCount === 0
    case 'visitedOnce':
      return visitCount <= 1
    case 'visitedTwice':
      return visitCount <= 2
    case 'visitedThrice':
      return visitCount <= 3
    default:
      return false
  }
}

const getDepositMessage = (depositSettings, visitCount, isLoggedIn) => {
  if (!isLoggedIn) {
    switch (depositSettings.depositCondition) {
      case 'all':
        return '此服務需要支付定金'
      case 'newCustomer':
        return '新客戶需要支付定金'
      case 'visitedOnce':
        return '訪問次數2次以內需要支付定金'
      case 'visitedTwice':
        return '訪問次數3次以內需要支付定金'
      case 'visitedThrice':
        return '訪問次數4次以內需要支付定金'
      default:
        return '此服務可能需要支付定金'
    }
  }

  // 登入用戶的提示信息
  if (depositSettings.depositCondition === 'all') {
    return '此服務需要支付定金'
  }
  return `您的訪問次數為${
    visitCount === 0 ? '首次' : `${visitCount}次`
  }，需要支付定金`
}

const BookingStepOne = ({
  business,
  product,
  setSelectedStylist,
  nextStep,
  depositSettings
}) => {
  const { id: storeId } = useParams()
  const [stylists, setStylists] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [visitCount, setVisitCount] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()
  const { user } = useAuth() // 獲取用戶登入狀態

  // 獲取訪問次數
  useEffect(() => {
    const fetchVisitCount = async () => {
      if (!user) {
        // 未登入用戶，直接顯示 Modal
        if (depositSettings?.isEnabled) {
          setShowModal(true)
        }
        setIsLoading(false)
        return
      }

      try {
        setIsLoading(true)
        const response = await api.get(
          `/api/client/bookings/visit-count/${storeId}`
        )
        setVisitCount(response.data.visitCount)

        if (
          depositSettings &&
          shouldShowDeposit(depositSettings, response.data.visitCount)
        ) {
          setShowModal(true)
        }
      } catch (error) {
        console.error('獲取訪問次數失敗:', error)
        message.error('獲取訪問記錄失敗')
      } finally {
        setIsLoading(false)
      }
    }

    if (storeId) {
      fetchVisitCount()
    }
  }, [storeId, depositSettings, user])

  useEffect(() => {
    if (product.stylists && product.stylists.length > 0) {
      const availableStylists = business.stylists.filter((stylist) =>
        product.stylists.includes(stylist._id)
      )
      setStylists(availableStylists)
    } else {
      setStylists([])
    }
  }, [business, product])

  const parseTags = (tags) => {
    if (Array.isArray(tags)) {
      return tags
    }
    return []
  }

  const filteredStylists = [DEFAULT_STYLIST, ...stylists].filter((stylist) =>
    stylist.name.toLowerCase().includes(searchTerm.toLowerCase())
  )
  const handleGoBack = () => {
    navigate(`/store/${business.username}`)
  }

  return (
    <LeftPane>
      <BackButton onClick={handleGoBack}>
        <ArrowLeft size={20} style={{ marginRight: '5px' }} />
        返回店家
      </BackButton>
      <SectionTitle>選擇員工</SectionTitle>
      <SearchWrapper>
        <SearchIcon size={20} />
        <SearchInput
          placeholder='搜尋員工'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </SearchWrapper>
      <EmployeeContainer>
        {filteredStylists.length > 0 ? (
          filteredStylists.map((stylist) => {
            const tags = parseTags(stylist.tags)
            const displayTags = getDisplayTags(tags)
            return (
              <EmployeeCard
                key={stylist._id}
                whileHover={{ scale: 1.03 }}
                whileTap={{ scale: 0.98 }}
              >
                <EmployeeAvatar
                  src={`${PHOTO_URL}${stylist.avatar}` || DEFAULT_AVATAR}
                  alt={stylist?.name}
                  onClick={() => {
                    setSelectedStylist(stylist._id)
                    nextStep()
                  }}
                />
                <StylistInfo>
                  <StylistName>{stylist.name}</StylistName>
                  {stylist._id === null ? (
                    <StylistDescription>接受店家現場安排</StylistDescription>
                  ) : (
                    <>
                      <StylistTags>
                        {displayTags.map((tag, index) => (
                          <StylistTag key={index}>{tag}</StylistTag>
                        ))}
                      </StylistTags>
                      <StylistDescription>
                        {stylist.description || '請於預約時填寫需求'}
                      </StylistDescription>
                    </>
                  )}
                  <SelectButton
                    onClick={() => {
                      setSelectedStylist(stylist._id)
                      nextStep()
                    }}
                  >
                    選擇
                  </SelectButton>
                </StylistInfo>
              </EmployeeCard>
            )
          })
        ) : (
          <EmptyState>沒有找到匹配的員工</EmptyState>
        )}
      </EmployeeContainer>
      {showModal && (
        <ModalOverlay>
          <ModalContent>
            <ModalTitle>預約注意事項</ModalTitle>
            <ModalText>請注意！</ModalText>
            <ModalText>
              {getDepositMessage(depositSettings, visitCount, !!user)}
            </ModalText>
            <ModalText>
              預約定金請{depositSettings.autoCancelHours}小時內付款
            </ModalText>
            <ModalText>目前接受銀行轉帳，收到款項後店家會確認預約。</ModalText>
            {!user && (
              <ModalText style={{ color: '#ff4d4f' }}>
                請先登入以查看您的實際定金要求
              </ModalText>
            )}
            <ConfirmButton onClick={() => setShowModal(false)}>
              確認
            </ConfirmButton>
          </ModalContent>
        </ModalOverlay>
      )}
    </LeftPane>
  )
}

export default BookingStepOne
