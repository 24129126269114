import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import api from '../utils/api'
import { PHOTO_URL } from '../utils/apiutils'
import { Popup } from 'antd-mobile'
import Loading from '../utils/Loading'
import { categories, translateCategoryToChinese } from '../utils/utils'
import { useNavigate, useParams } from 'react-router-dom'
import { Tag, Input, Pagination, Empty, Divider } from 'antd'
import { Helmet } from 'react-helmet-async'
import {
  SearchOutlined,
  EnvironmentOutlined,
  DownOutlined
} from '@ant-design/icons'
import cityData from '../utils/cityData'

const Container = styled.div`
  display: flex;
  font-family: Arial, sans-serif;

  @media (max-width: 820px) {
    flex-direction: column;
  }
`

const FilterContent = styled.div`
  padding: 20px;
  flex: 1;

  @media (max-width: 820px) {
    padding: 10px;
  }
`

const MainContent = styled.div`
  padding: 0px 20px 20px 20px;
`

const CategoriesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  @media (max-width: 820px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin: 10px 0 20px 0;
  }
`

const CategoryItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 10px;
  background-color: ${(props) => (props.$active ? '#D5728A' : '#f9f9f9')};
  color: ${(props) => (props.$active ? '#FFF' : '#000')};
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => (props.$active ? '#D5728A' : '#eee')};
  }
  @media (max-width: 820px) {
    justify-content: center;
    padding: 5px;
    margin: 0;
  }
`

const CategoryIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
  @media (max-width: 820px) {
    width: 20px;
    height: 20px;
  }
`

const CategoryName = styled.div`
  font-size: 16px;
  @media (max-width: 820px) {
    font-size: 14px;
  }
`

const PhotoWall = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;

  @media (max-width: 820px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 4px;
    padding: 4px;
  }
`

const PhotoItem = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.02);
  }

  @media (max-width: 820px) {
    border-radius: 0;
    box-shadow: none;
  }
`

const PhotoImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
`

const PhotoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 50%
  );
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 15px;
  opacity: 0;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }

  @media (max-width: 820px) {
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 70%
    );
  }
`

const StylistName = styled.h3`
  color: white;
  margin: 0;
  font-size: 18px;
  font-weight: 600;

  @media (max-width: 820px) {
    font-size: 14px;
  }
`

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (max-width: 820px) {
    margin: 10px 10px 0 10px;
    flex-direction: row;
  }
`

const SearchInput = styled(Input)`
  border-radius: 12px;
  padding: 8px 15px;

  .ant-input {
    font-size: 14px;
  }

  .ant-input-prefix {
    margin-right: 8px;
    color: #d5728a;
  }
`

const SearchButton = styled.button`
  background-color: #d5728a;
  color: white;
  border: none;
  border-radius: 12px;
  padding: 12px 24px;
  font-size: 16px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;

  &:hover {
    background-color: ${(props) => (props.disabled ? '#ccc' : '#c4617a')};
    transform: ${(props) => (props.disabled ? 'none' : 'translateY(-2px)')};
    box-shadow: ${(props) =>
      props.disabled ? 'none' : '0 4px 8px rgba(0, 0, 0, 0.15)'};
  }
`

const DesktopRegionFilter = styled.div`
  margin-bottom: 20px;
  @media (max-width: 820px) {
    display: none;
  }
`

const RegionTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
`

const RegionAll = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-bottom: 10px;
  background-color: ${(props) => (props.$active ? '#D5728A' : '#f9f9f9')};
  color: ${(props) => (props.$active ? '#FFF' : '#000')};
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background-color: #c56b81;
    color: #fff;
  }
  @media (max-width: 820px) {
    padding: 7px;
  }
`

const RegionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
`

const RegionItem = styled.div`
  padding: 5px;
  background-color: ${(props) => (props.$active ? '#D5728A' : '#f9f9f9')};
  color: ${(props) => (props.$active ? '#FFF' : '#000')};
  border-radius: 10px;
  cursor: pointer;
  text-align: center;

  &:hover {
    background-color: ${(props) => (props.$active ? '#D5728A' : '#eee')};
  }

  @media (max-width: 820px) {
    grid-column: span 1;
  }
`

const DistrictFilter = styled.div`
  margin-bottom: 20px;
  @media (max-width: 820px) {
    display: none;
  }
`

const EmptyContainer = styled.div`
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
`

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  @media (max-width: 820px) {
    .ant-pagination {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
    }

    .ant-pagination-total-text {
      flex-basis: 100%;
      text-align: center;
      margin-bottom: 15px;
    }

    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-item,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      margin: 0 4px;
      display: inline-flex;
      align-items: center;
    }

    .ant-pagination-options {
      margin-top: 10px;
      flex-basis: 100%;
      display: flex;
      justify-content: center;
    }
  }
`

const DesignerInfo = styled.div`
  padding: 10px;
  color: white;
  text-align: left;
`

const BusinessInfo = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 4px;
`

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 6px;

  @media (max-width: 820px) {
    /* 在手機版隱藏除了最後一個以外的所有標籤 */
    & > *:not(:last-child) {
      display: none;
    }
  }
`

const SkillTag = styled(Tag)`
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 10px;
  margin: 0;
  background-color: rgba(213, 114, 138, 0.8);
  border: none;
  color: white;
`

const ExperienceTag = styled(Tag)`
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 10px;
  margin: 0;
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  color: white;
`
const MobileRegionButton = styled.button`
  display: none;
  padding: 12px 15px;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 12px;
  margin: 0px 20px 10px 20px;
  font-size: 16px;

  &:active {
    background: #f5f5f5;
  }

  @media (max-width: 820px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
    transition: all 0.3s ease;
  }
`

const RegionButtonContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #333;
`

const RegionIcon = styled(EnvironmentOutlined)`
  color: #d5728a;
  font-size: 18px;
`

const RegionText = styled.span`
  color: ${(props) => (props.$hasSelection ? '#333' : '#999')};
`
const DownIcon = styled(DownOutlined)`
  color: #999;
  font-size: 12px;
  margin-left: auto;
`

const PopupContent = styled.div`
  padding: 20px;
  padding-bottom: 80px; // 添加底部 padding
  flex: 1;
  overflow-y: auto;
  position: relative;
`

const PopupTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
`

const PopupGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
`
const DistrictsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #eee;
`

const DistrictItem = styled(RegionItem)`
  font-size: 14px;
  padding: 5px;
`

const BackButtonContainer = styled.div`
  padding: 16px;
  background: white;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.06);
`

const BackButton = styled.button`
  width: 100%;
  background-color: #d5728a;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 12px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #c4617a;
  }

  &:active {
    transform: scale(0.98);
  }
`

const StylistsPage = () => {
  const [stylists, setStylists] = useState([])
  const [selectedCategory, setSelectedCategory] = useState('all')
  const [loading, setLoading] = useState(true)
  const [keyword, setKeyword] = useState('')
  const [selectedCity, setSelectedCity] = useState('all')
  const [selectedDistrict, setSelectedDistrict] = useState(null)
  const [districts, setDistricts] = useState([])
  const [showPopup, setShowPopup] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalResults, setTotalResults] = useState(0)
  const navigate = useNavigate()
  const { category, city, district, page } = useParams()
  const [searchText, setSearchText] = useState('')
  const [isSearching, setIsSearching] = useState(false)

  // 創建一個統一的 API 請求函數
  const fetchDesigners = async (options = {}) => {
    try {
      setLoading(true)
      const params = {
        page: options.page || currentPage,
        limit: 30
      }

      if (options.city && options.city !== 'all') {
        params.region = options.city
        if (options.district) {
          params.district = options.district
        }
      }

      if (options.category && options.category !== 'all') {
        params.skillType = options.category
      }

      if (options.searchText?.trim()) {
        params.keyword = options.searchText.trim()
      }

      const response = await api.get('/api/search/designers', { params })
      setStylists(response.data.designers)
      setTotalResults(response.data.totalResults)
      setShowPopup(false)
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  // 初始化時從 URL 參數設置狀態
  useEffect(() => {
    setSelectedCategory(category || 'all')
    setSelectedCity(city === 'all-city' ? 'all' : city)
    setSelectedDistrict(district === 'all-district' ? null : district)
    setCurrentPage(parseInt(page) || 1)
    setShowPopup(false)

    // 设置districts
    if (city && city !== 'all-city') {
      const cityDistrict = cityData.districts.find((d) => d.name === city)
      setDistricts(cityDistrict ? cityDistrict.areas : [])
    }

    fetchDesigners({
      city: city === 'all-city' ? 'all' : city,
      district: district === 'all-district' ? null : district,
      category: category || 'all',
      page: parseInt(page) || 1
    })
  }, [category, city, district, page])

  // 修改導航函數
  const updateURL = (newCategory, newCity, newDistrict, newPage) => {
    navigate(`/stylists/${newCategory}/${newCity}/${newDistrict}/${newPage}`)
  }

  // 修改處理函數
  const handleCategorySelect = async (categoryType) => {
    const newCity = selectedCity === 'all' ? 'all-city' : selectedCity
    const newDistrict = selectedDistrict || 'all-district'
    updateURL(categoryType, newCity, newDistrict, 1)
  }

  const handleCitySelect = async (city) => {
    const newCity = city === 'all' ? 'all-city' : city
    // 更新districts数据
    if (city === 'all') {
      setDistricts([])
    } else {
      // 从cityData.districts中找到对应城市的区域数据
      const cityDistrict = cityData.districts.find((d) => d.name === city)
      setDistricts(cityDistrict ? cityDistrict.areas : [])
    }
    setSelectedDistrict(null) // 重置已选择的区域
    updateURL(selectedCategory, newCity, 'all-district', 1)
  }

  const handleDistrictSelect = async (district) => {
    const newCity = selectedCity === 'all' ? 'all-city' : selectedCity
    updateURL(selectedCategory, newCity, district, 1)
  }

  // 修改分頁處理
  const handlePageChange = (newPage) => {
    const newCity = selectedCity === 'all' ? 'all-city' : selectedCity
    const newDistrict = selectedDistrict || 'all-district'
    updateURL(selectedCategory, newCity, newDistrict, newPage)
  }

  // 修改 clearSearch
  const clearSearch = async () => {
    setSearchText('')
    setKeyword('')
    setCurrentPage(1)

    await fetchDesigners({
      city: selectedCity,
      district: selectedDistrict,
      category: selectedCategory,
      searchText: '',
      page: 1
    })
  }

  // 移除或簡化 useEffect
  useEffect(() => {
    fetchDesigners({
      city: selectedCity,
      district: selectedDistrict,
      category: selectedCategory,
      searchText,
      page: currentPage
    })
  }, []) // 只在組件初始化時執行一次

  const handleSearch = async () => {
    if (!searchText.trim()) {
      setKeyword('')
      setCurrentPage(1)
      // 當搜索文字為空時也要重新獲取數據
      await fetchDesigners({
        city: selectedCity,
        district: selectedDistrict,
        category: selectedCategory,
        searchText: '',
        page: 1
      })
      return
    }

    setIsSearching(true)
    setKeyword(searchText.trim())
    setCurrentPage(1)

    // 執行搜索
    await fetchDesigners({
      city: selectedCity,
      district: selectedDistrict,
      category: selectedCategory,
      searchText: searchText.trim(),
      page: 1
    })
    setIsSearching(false)
  }

  // 過濾有效的設計師數據
  const validStylists = stylists.filter(
    (designer) =>
      designer.name && designer.skillsType && designer.skillsType.length > 0
  )

  const getServiceTypeName = (serviceType) => {
    const category = categories.find((cat) => cat.type === serviceType)
    return category ? category.name : serviceType
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Container>
      <Helmet>
        <title>{`${selectedCity === 'all' ? '台灣' : selectedCity}${
          selectedDistrict === null ? '' : selectedDistrict
        }${
          category === 'all'
            ? '美容美甲美髮睫毛紋繡'
            : translateCategoryToChinese(category)
        }設計師 & 線上預約優惠 - 美感｜BeauteFeel`}</title>
        <meta
          name='description'
          content={`全台美容線上預約平台，不論美甲美容美髮美睫霧唇霧眉，輕鬆一鍵查詢並完成預約，找到最符合您需求的美容專家!`}
        />
      </Helmet>
      <FilterContent>
        <SearchContainer>
          <SearchInput
            placeholder='搜索設計師名稱、特色或標籤'
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onPressEnter={handleSearch}
            allowClear
            onClear={clearSearch}
          />
          <SearchButton
            disabled={isSearching}
            loading={isSearching}
            onClick={handleSearch}
          >
            搜索
          </SearchButton>
        </SearchContainer>

        <DesktopRegionFilter>
          <Divider style={{ margin: '20px 0 0 0' }}>
            <RegionTitle>縣市篩選</RegionTitle>
          </Divider>
          <RegionAll
            $active={selectedCity === 'all'}
            onClick={() => handleCitySelect('all')}
          >
            全部地區
          </RegionAll>
          <RegionGrid>
            {cityData.counties.map((city) => (
              <RegionItem
                key={city}
                $active={selectedCity === city}
                onClick={() => handleCitySelect(city)}
              >
                {city}
              </RegionItem>
            ))}
          </RegionGrid>
        </DesktopRegionFilter>

        {selectedCity !== 'all-city' && districts.length > 0 && (
          <DistrictFilter>
            <RegionTitle>區域篩選</RegionTitle>
            <RegionGrid>
              {districts.map((district) => (
                <RegionItem
                  key={district}
                  $active={selectedDistrict === district}
                  onClick={() => handleDistrictSelect(district)}
                >
                  {district}
                </RegionItem>
              ))}
            </RegionGrid>
          </DistrictFilter>
        )}
      </FilterContent>

      {/* 手机版按钮 */}
      <MobileRegionButton onClick={() => setShowPopup(true)}>
        <RegionButtonContent>
          <RegionIcon />
          <RegionText $hasSelection={selectedCity !== 'all'}>
            {selectedCity === 'all' ? '按地區搜尋' : selectedCity}
          </RegionText>
        </RegionButtonContent>
        <DownIcon />
      </MobileRegionButton>

      {/* 手机版弹窗 */}
      <Popup
        visible={showPopup}
        onMaskClick={() => setShowPopup(false)}
        position='bottom'
        bodyStyle={{ height: '90vh', display: 'flex', flexDirection: 'column' }}
      >
        <PopupContent>
          <PopupTitle>選擇地區</PopupTitle>
          <RegionAll
            $active={selectedCity === 'all'}
            onClick={() => handleCitySelect('all')}
          >
            全部地區
          </RegionAll>
          <PopupGrid>
            {cityData.counties.map((city) => (
              <RegionItem
                key={city}
                $active={selectedCity === city}
                onClick={() => handleCitySelect(city)}
              >
                {city}
              </RegionItem>
            ))}
          </PopupGrid>

          {/* 显示区域选择 */}
          {selectedCity && districts.length > 0 && (
            <DistrictsGrid>
              {districts.map((district) => (
                <DistrictItem
                  key={district}
                  $active={selectedDistrict === district}
                  onClick={() => handleDistrictSelect(district)}
                >
                  {district}
                </DistrictItem>
              ))}
            </DistrictsGrid>
          )}
        </PopupContent>
        <BackButtonContainer>
          <BackButton onClick={() => setShowPopup(false)}>返回</BackButton>
        </BackButtonContainer>
      </Popup>

      <MainContent>
        <CategoriesContainer>
          {categories.map((category, index) => (
            <CategoryItem
              key={index}
              $active={selectedCategory === category.type}
              onClick={() => handleCategorySelect(category.type)}
            >
              <CategoryIcon src={`${category.icon}`} alt={category.name} />
              <CategoryName>{category.name}</CategoryName>
            </CategoryItem>
          ))}
        </CategoriesContainer>

        <PhotoWall>
          {validStylists.length > 0 ? (
            validStylists.map((designer) => (
              <PhotoItem
                key={designer._id}
                onClick={() => navigate(`/${designer.username}`)}
              >
                <PhotoImage
                  src={
                    designer.avatar?.startsWith('/avatars')
                      ? `${PHOTO_URL}${designer.avatar}`
                      : `${PHOTO_URL}/public/photos/stylist_default.png`
                  }
                  alt={designer.name}
                />
                <PhotoOverlay>
                  <DesignerInfo>
                    <StylistName>{designer.name}</StylistName>
                    <BusinessInfo>
                      {designer.employeeType} ·{' '}
                      {designer.area?.join('/') || '暫無地區'}
                    </BusinessInfo>
                    <TagsContainer>
                      {/* 在手機版只顯示最後一個 tag */}
                      {window.innerWidth <= 820 ? (
                        // 如果有 tags，只顯示最後一個
                        designer.tags?.slice(-1).map((tag, idx) => (
                          <SkillTag key={`tag-${idx}`} color='#87d068'>
                            {tag}
                          </SkillTag>
                        ))
                      ) : (
                        // 桌面版保持原樣
                        <>
                          {designer.skillsType.slice(0, 2).map((skill, idx) => (
                            <SkillTag key={idx}>
                              {getServiceTypeName(skill)}
                            </SkillTag>
                          ))}
                          {designer.yearsOfExperience > 0 && (
                            <ExperienceTag>
                              {designer.yearsOfExperience}年經驗
                            </ExperienceTag>
                          )}
                          {designer.tags?.slice(0, 1).map((tag, idx) => (
                            <SkillTag key={`tag-${idx}`} color='#87d068'>
                              {tag}
                            </SkillTag>
                          ))}
                        </>
                      )}
                    </TagsContainer>
                  </DesignerInfo>
                </PhotoOverlay>
              </PhotoItem>
            ))
          ) : (
            <EmptyContainer>
              <Empty
                description={
                  <div>
                    <p>
                      沒有找到符合
                      {selectedCity !== 'all' &&
                        selectedCity &&
                        selectedDistrict}
                      條件的設計師
                    </p>
                    <p style={{ fontSize: '12px', color: '#999' }}>
                      {selectedCategory !== 'all' && '試試切換其他分類'}
                      {selectedCity !== 'all' && '或更換其他地區'}
                      {keyword && '或修改搜索關鍵詞'}
                    </p>
                  </div>
                }
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            </EmptyContainer>
          )}
        </PhotoWall>

        {validStylists.length > 0 && (
          <PaginationContainer>
            <Pagination
              current={currentPage}
              total={totalResults}
              pageSize={30}
              onChange={handlePageChange}
              showSizeChanger={false}
              showQuickJumper
              showTotal={(total, range) =>
                `第 ${range[0]}-${range[1]} 項，共 ${total} 位設計師`
              }
            />
          </PaginationContainer>
        )}
      </MainContent>
    </Container>
  )
}

export default StylistsPage
