import React, { useEffect } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation
} from 'react-router-dom'
import ReactGA from 'react-ga4'

// 佈局組件
import NavBar from '../components/Navbar'
import Footer from '../components/Footer'

// 頁面組件
import HomePage from '../container/HomePage'
import LoginPage from '../container/LoginPage'
import EditAccountPage from '../container/EditAccountPage'
import ServicesPage from '../container/ServicesPage'
import BusinessesPage from '../container/BusinessesPage'
import SearchPage from '../container/SearchPage'
import StorePage from '../container/StorePage'
import StylistsPage from '../container/StylistsPage'
import BookingService from '../container/BookingService'
import BlogPage from '../container/BlogPage'
import ArticleDetailPage from '../container/ArticleDetailPage'
import ArticlePage from '../container/ArticlePage'
import AddNewArticle from '../container/AddNewArticle'
import PrivacyPage from '../container/PrivacyPage'
import TermsPage from '../container/TermsPage'
import CopyrightPage from '../container/CopyRightPage'
import VerifyEmailPage from '../container/VerifyEmailPage'
import CheckEmailPage from '../container/CheckEmailPage'
import BookingSuccessPage from '../container/BookingSuccessPage'
import UserAppointmentList from '../container/UserAppointmentList'
import UserBookingConfirmation from '../container/UserBookingConfirmation'
import StylistProfile from '../container/StylistProfile'
import NotFoundPage from '../container/NotFoundPage'
import AllNotificationsPage from '../container/AllNotificationsPage'
import NotificationDetailPage from '../container/NotificationDetailPage'
import ModelsPage from '../container/Models'
import UserFavoriteList from '../container/UserFavoriteList'
import BreadcrumbWrapper from '../components/BreadCrumbWrapper'
import SearchAllResult from '../container/SearchAllResult'
import SearchByType from '../container/SearchByType'
import NearbyMapPage from '../container/NearbyMapPage'
import AdvancedSearchResultPage from '../container/AdvancedSearchResultPage'
import ChangeEmailPasswordPage from '../components/ChangeEmailPasswordPage'
import WriteReview from '../components/WriteReview'
import LineUserEmailSetup from '../container/LineUserEmailSetup'
import ModelRegistrationForm from '../components/ModelRegistrationForm'
import UserDashboard from '../container/UserDashBoard'
import UserModelSetting from '../container/UserModelSetting'
import HiringPage from '../container/HiringPage'
// 路由保護組件
import ProtectedRoute from './ProtectRoute'

// GA 初始化
if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('G-ERXBN4PZCC')
}

// GA 追踪组件
const GATracker = () => {
  const location = useLocation()

  useEffect(() => {
    // 使用 send 方法代替 pageview
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search
    })
  }, [location])

  return null
}

// 主路由組件
const AppRouter = () => {
  return (
    <Router>
      <GATracker />
      <NavBar />
      <BreadcrumbWrapper>
        <Routes>
          {/* 主頁 */}
          <Route path='/' element={<HomePage />} />

          {/* 用戶相關路由 */}
          <Route path='/login' element={<LoginPage />} />
          <Route
            path='/account'
            element={
              <ProtectedRoute>
                <EditAccountPage />
              </ProtectedRoute>
            }
          />
          <Route
            path='/change/:type'
            element={
              <ProtectedRoute>
                <ChangeEmailPasswordPage />
              </ProtectedRoute>
            }
          />
          {/* Line驗證郵箱的路由 */}
          <Route
            path='/line-user-email-setup'
            element={
              <ProtectedRoute>
                <LineUserEmailSetup />
              </ProtectedRoute>
            }
          />
          <Route
            path='/change-line-user-email'
            element={<LineUserEmailSetup />}
          />
          <Route
            path='/line-user-email-complete'
            element={<LineUserEmailSetup />}
          />
          <Route
            path='/favorites/:category'
            element={
              <ProtectedRoute>
                <UserFavoriteList />
              </ProtectedRoute>
            }
          />
          <Route
            path='/verify-email'
            element={
              <ProtectedRoute>
                <VerifyEmailPage />
              </ProtectedRoute>
            }
          />
          <Route
            path='/check-email'
            element={
              <ProtectedRoute>
                <CheckEmailPage />
              </ProtectedRoute>
            }
          />

          {/* 服務相關路由 */}
          <Route
            path='/services'
            element={
              <Navigate to='/services/all/all-city/all-district/1' replace />
            }
          />
          <Route
            path='/services/:category/:city/:district/:page'
            element={<ServicesPage />}
          />
          <Route
            path='/store'
            element={<Navigate to='/store/all/all/1' replace />}
          />

          {/* 商家列表頁面 - 使用新的路徑 */}
          <Route
            path='/businesses'
            element={<Navigate to='/businesses/all/all/1' replace />}
          />
          <Route
            path='/businesses/:category/:region/:page'
            element={<BusinessesPage />}
          />

          {/* 商家詳情頁面 - 保持原有路徑 */}
          <Route path='/store/:username/*' element={<StorePage />}>
            <Route index element={<Navigate to='services' replace />} />
            <Route path='services' element={<StorePage />} />
            <Route path='stylists' element={<StorePage />} />
            <Route path='portfolio' element={<StorePage />} />
            <Route path='about' element={<StorePage />} />
            <Route path='reviews' element={<StorePage />} />
          </Route>

          <Route
            path='/stylists'
            element={
              <Navigate to='/stylists/all/all-city/all-district/1' replace />
            }
          />
          <Route
            path='/stylists/:category/:city/:district/:page'
            element={<StylistsPage />}
          />

          <Route
            path='/booking/:username/:productId'
            element={<BookingService />}
          />
          <Route path='/booking-success' element={<BookingSuccessPage />} />
          <Route
            path='/user-appointment-list/:username'
            element={
              <ProtectedRoute>
                <UserAppointmentList />
              </ProtectedRoute>
            }
          >
            <Route index element={<Navigate to='all/1' replace />} />
            <Route path=':tab/:page' element={<UserAppointmentList />} />
          </Route>
          <Route path='/booking-action' element={<UserBookingConfirmation />} />
          <Route
            path='/write-review/:appointmentId'
            element={<WriteReview />}
          />

          {/* 博客相關路由 */}
          <Route path='/articles' element={<BlogPage />} />
          <Route path='/articles/:id' element={<ArticleDetailPage />} />
          <Route path='/category/:category' element={<ArticlePage />} />
          <Route
            path='/add-new-articles'
            element={
              <ProtectedRoute>
                <AddNewArticle />
              </ProtectedRoute>
            }
          />

          {/* 搜索相關路由 */}
          <Route path='/search-all-results' element={<SearchAllResult />} />

          <Route path='/search' element={<SearchPage />} />
          <Route
            path='/search/:city/:area/:category/:date/:timeRange'
            element={<SearchPage />}
          />
          <Route path='/search/type' element={<SearchByType />} />

          {/* 新添加的高级搜索结果页面路由 */}
          <Route
            path='/advanced-search'
            element={<AdvancedSearchResultPage />}
          />

          {/* 附近地圖 */}
          <Route path='/nearby-salons' element={<NearbyMapPage />} />

          {/* 造型師個人資料路由 */}
          <Route path='/:username' element={<StylistProfile />}>
            <Route index element={<Navigate to='services' replace />} />
            <Route path='services' element={<StylistProfile />}></Route>
            <Route path='portfolio' element={<StylistProfile />}>
              <Route path=':portfolioId' element={<StylistProfile />} />
            </Route>

            <Route path='about' element={<StylistProfile />} />
            <Route path='experience' element={<StylistProfile />} />
            <Route path='reviews' element={<StylistProfile />} />
          </Route>

          {/* 模特兒相關路由 */}
          <Route path='/models' element={<ModelsPage />} />
          <Route path='/register-model' element={<ModelRegistrationForm />} />
          <Route path='/model-setting' element={<UserModelSetting />} />
          <Route
            path='/hiring'
            element={
              <ProtectedRoute>
                <HiringPage />
              </ProtectedRoute>
            }
          />
          {/* 通知相關路由 */}
          <Route
            path='/notifications'
            element={
              <ProtectedRoute>
                <AllNotificationsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path='/notifications/:id'
            element={
              <ProtectedRoute>
                <NotificationDetailPage />
              </ProtectedRoute>
            }
          />

          {/* 其他頁面路由 */}
          <Route path='/privacy' element={<PrivacyPage />} />
          <Route path='/terms' element={<TermsPage />} />
          <Route path='/copyright' element={<CopyrightPage />} />

          {/* NotFound 頁面路由 */}
          <Route path='/not-found' element={<NotFoundPage />} />

          {/* 捕獲所有未匹配的路由並重定向到 NotFound 頁面 */}
          <Route path='*' element={<Navigate to='/not-found' replace />} />

          <Route
            path='/dashboard'
            element={
              <ProtectedRoute>
                <UserDashboard />
              </ProtectedRoute>
            }
          />
        </Routes>
      </BreadcrumbWrapper>
      <Footer />
    </Router>
  )
}

export default AppRouter
