import React, { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import axios from 'axios'
import { API_BASE_URL, PHOTO_URL } from '../utils/apiutils'
import { HeartOutlined, HeartFilled } from '@ant-design/icons'
import Loading from '../utils/Loading'
import { getTagColor } from '../utils/utils'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { message, Drawer, Select } from 'antd'
import { useAuth } from '../hooks/AuthContext'
import api from '../utils/api'
import { BiSolidCategory } from 'react-icons/bi'
import { FilterOutlined } from '@ant-design/icons'
import { tags } from '../utils/utils'

// 定義淡入動畫
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

// 定義縮放動畫
const scaleUp = keyframes`
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1);
  }
`

const Container = styled.div`
  font-family: Arial, sans-serif;
  padding: 20px;
  animation: ${fadeIn} 1s ease-in-out;
  background-color: #fff;
  border-radius: 0 0 20px 20px;
  @media (max-width: 820px) {
    background-color: #fafafa;
  }
`

const ProductsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
`

const ProductCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #eee;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  animation: ${fadeIn} 1s ease-in-out, ${scaleUp} 0.5s ease-in-out;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.05);
  }
`

const ProductImage = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
  background-color: #f0f0f0;
`

const ProductDetails = styled.div`
  padding: 10px;
`

const ProductName = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: left;
`

const ProductPrice = styled.div`
  padding-top: 10px;
  color: #d5728a;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
`

const CardTagContainer = styled.div`
  display: flex;
  justify-content: left;
  margin-bottom: 10px;
`

const CardTag = styled.div`
  background-color: ${(props) => props.color};
  padding: 3px 6px;
  border-radius: 5px;
  margin-right: 5px;
`

const CardTagText = styled.div`
  font-size: 12px;
  color: #896868;
`

const Box = styled.div`
  display: flex;
  justify-content: space-between;
`

const ReserveButton = styled.div`
  padding: 5px;
  border-radius: 5px;
  background-color: #d5728a;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #bf5170;
  }
`

const ReserveButtonText = styled.div`
  padding: 0px 10px;
  font-size: 16px;
  color: #fff;
`

const FavoriteButton = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  height: 28px;
  width: 28px;
  background-color: #fff;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  font-size: 20px;
  color: ${(props) => (props.favorited ? '#d5728a' : '#aaa')};
  transition: color 0.3s;
  &:hover {
    color: #d5728a;
  }
`
const CategoryContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
  background-color: #f7f7f7;
  padding: 5px;
  border-radius: 5px;

  @media (max-width: 768px) {
    justify-content: space-between;
  }
`

const CategorySelect = styled(Select)`
  min-width: 100px;
  max-width: 200px;
  .ant-select-selector {
    border: none !important;
  }
  @media (max-width: 768px) {
    display: none;
  }
`

const MobileCategoryButton = styled.button`
  display: none;
  border: none;
  background-color: #d5728a;
  color: #fff;
  padding: 6px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    gap: 5px;
  }
`
const PcCategoryTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 15px;
  font-weight: 500;
  color: #d5728a;

  @media (max-width: 768px) {
    display: none;
  }
`

const CategoryTag = styled.div`
  display: inline-flex;
  align-items: center;
  background-color: ${(props) => (props.$active ? '#d5728a' : '#f0f0f0')};
  color: ${(props) => (props.$active ? '#fff' : '#d5728a')};
  padding: 5px 10px;
  border-radius: 20px;
  margin: 5px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) => (props.$active ? '#C4617A' : '#e0e0e0')};
  }
`

const TagsContainer = styled.div`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 10px;
  margin-bottom: 20px;
`

const MobileFilterResult = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-size: 14px;
    color: #333;
  }
`

const Tag = styled.div`
  display: inline-block;
  padding: 5px 10px;
  margin-right: 10px;
  background-color: ${(props) => (props.$active ? props.$color : '#f0f0f0')};
  color: ${(props) => (props.$active ? '#000' : '#333')};
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) => (props.$active ? props.$color : '#e0e0e0')};
  }
`

const StylistService = ({ stylistId, businessId, businessUsername }) => {
  const [assignedServices, setAssignedServices] = useState([])
  const [loading, setLoading] = useState(true)
  const [favorites, setFavorites] = useState([])
  const [categories, setCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState('all')
  const [selectedCategoryName, setSelectedCategoryName] = useState('全部')
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [selectedTags, setSelectedTags] = useState([])
  const [selectedProductId, setSelectedProductId] = useState(null)
  const navigate = useNavigate()
  const { user } = useAuth()
  const location = useLocation()
  const { username } = useParams()

  useEffect(() => {
    const fetchAssignedServices = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/stylist/${stylistId}/assigned-services`
        )
        setAssignedServices(response.data.assignedServices)
        setCategories(response.data.businessCategories)
        setLoading(false)

        // 从 URL 获取分类 ID
        const urlParams = new URLSearchParams(location.search)
        const categoryId = urlParams.get('category')
        if (categoryId) {
          setSelectedCategory(categoryId)
          const categoryName =
            response.data.businessCategories.find(
              (cat) => cat._id === categoryId
            )?.name || '全部'
          setSelectedCategoryName(categoryName)
        } else {
          setSelectedCategory('all')
          setSelectedCategoryName('全部')
        }

        // 从 URL 中获取产品 ID 并滚动到对应位置
        const productId = urlParams.get('productId')
        if (productId) {
          setSelectedProductId(productId)
          setTimeout(() => {
            const element = document.getElementById(productId)
            if (element) {
              element.scrollIntoView({ behavior: 'smooth', block: 'center' })
            }
          }, 500)
        }
      } catch (error) {
        console.error('Error fetching assigned services:', error)
        message.error('獲取指定服務失敗。')
        setLoading(false)
      }
    }

    const fetchFavoriteServices = async () => {
      if (user && user.userId) {
        try {
          const response = await api.get(
            `api/user/${user.userId}/favorite-services`
          )
          setFavorites(
            response.data.favoriteServices.map((service) => service.productId)
          )
        } catch (error) {
          console.error('獲取收藏服務失敗:', error)
        }
      }
    }

    fetchAssignedServices()
    fetchFavoriteServices()
  }, [stylistId, businessId, user, location])

  const toggleFavorite = async (serviceId) => {
    try {
      if (favorites.includes(serviceId)) {
        await api.delete(
          `/api/user/${user.userId}/favorite-services/${businessId}/${serviceId}`
        )
        setFavorites(favorites.filter((id) => id !== serviceId))
        message.success('已取消收藏')
      } else {
        await api.post(
          `/api/user/${user.userId}/favorite-services/${businessId}/${serviceId}`
        )
        setFavorites([...favorites, serviceId])
        message.success('已添加到收藏')
      }
    } catch (error) {
      console.error('收藏操作失败:', error)
      message.error('收藏操作失败，请稍后重试。')
    }
  }

  const handleCardClick = (productId, event) => {
    // 检查点击是否发生在预约按钮上
    if (!event.target.closest('.reserve-button')) {
      setSelectedProductId(productId)
      navigate(`/${username}/services?productId=${productId}`, {
        replace: true
      })
      const element = document.getElementById(productId)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }
  }

  const handleReserveClick = (businessUsername, productId, event) => {
    event.stopPropagation() // 阻止事件冒泡
    navigate(`/booking/${businessUsername}/${productId}`)
  }

  const handleCategoryChange = (value) => {
    setSelectedCategory(value)
    const categoryName =
      value === 'all'
        ? '全部'
        : categories.find((cat) => cat._id === value)?.name || '全部'
    setSelectedCategoryName(categoryName)
    navigate(
      `/${username}/services${value === 'all' ? '' : `?category=${value}`}`,
      {
        replace: true
      }
    )
    setDrawerVisible(false)
  }

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible)
  }

  const handleTagClick = (tagLabel) => {
    setSelectedTags((prevTags) =>
      prevTags.includes(tagLabel)
        ? prevTags.filter((tag) => tag !== tagLabel)
        : [...prevTags, tagLabel]
    )
  }

  const filteredServices = assignedServices.filter(
    (service) =>
      (selectedCategory === 'all' || service.category === selectedCategory) &&
      (selectedTags.length === 0 ||
        selectedTags.some((tag) => service.tags.includes(tag)))
  )

  if (loading) {
    return <Loading />
  }

  return (
    <Container>
      <CategoryContainer>
        <PcCategoryTitle>
          <BiSolidCategory style={{ marginRight: '5px' }} /> 按分類篩選
        </PcCategoryTitle>
        <CategorySelect
          placeholder='選擇分類'
          onChange={handleCategoryChange}
          value={selectedCategory}
          aria-label='選擇服務分類'
        >
          <Select.Option value='all'>全部</Select.Option>
          {categories.map((category) => (
            <Select.Option key={category._id} value={category._id}>
              {category.name}
            </Select.Option>
          ))}
        </CategorySelect>
        <MobileCategoryButton onClick={toggleDrawer} aria-label='打開分類選擇'>
          <FilterOutlined /> 分類
        </MobileCategoryButton>
        <MobileFilterResult>
          篩選的條件：{selectedCategoryName}
        </MobileFilterResult>
      </CategoryContainer>

      <Drawer
        title='選擇分類'
        placement='right'
        onClose={toggleDrawer}
        visible={drawerVisible}
        width={300}
      >
        <CategoryTag
          $active={selectedCategory === 'all'}
          onClick={() => handleCategoryChange('all')}
          role='button'
          aria-pressed={selectedCategory === 'all'}
        >
          全部
        </CategoryTag>
        {categories.map((category) => (
          <CategoryTag
            key={category._id}
            $active={selectedCategory === category._id}
            onClick={() => handleCategoryChange(category._id)}
            role='button'
            aria-pressed={selectedCategory === category._id}
          >
            {category.name}
          </CategoryTag>
        ))}
      </Drawer>

      <TagsContainer>
        {tags.map((tag) => (
          <Tag
            key={tag.label}
            $active={selectedTags.includes(tag.label)}
            $color={getTagColor(tag.label)}
            onClick={() => handleTagClick(tag.label)}
          >
            {tag.label}
          </Tag>
        ))}
      </TagsContainer>

      <ProductsContainer>
        {filteredServices.map((service) => (
          <ProductCard
            key={service._id}
            id={service._id}
            onClick={(event) => handleCardClick(service._id, event)}
            style={{
              boxShadow:
                selectedProductId === service._id ? '0 0 0 2px #f1c2c2' : 'none'
            }}
          >
            <ProductImage
              src={`${PHOTO_URL}${service.image}`}
              alt={service.name}
            />
            <FavoriteButton
              favorited={favorites.includes(service._id)}
              onClick={() => toggleFavorite(service._id)}
            >
              {favorites.includes(service._id) ? (
                <HeartFilled />
              ) : (
                <HeartOutlined />
              )}
            </FavoriteButton>
            <ProductDetails>
              {service.tags && (
                <CardTagContainer>
                  {service.tags.slice(0, 3).map((tag, index) => (
                    <CardTag key={index} color={getTagColor(tag)}>
                      <CardTagText>{tag}</CardTagText>
                    </CardTag>
                  ))}
                </CardTagContainer>
              )}
              <ProductName>{service.name}</ProductName>
              <Box>
                <ProductPrice>$ {service.price}</ProductPrice>
                <ReserveButton
                  className='reserve-button'
                  onClick={(event) =>
                    handleReserveClick(businessUsername, service._id, event)
                  }
                >
                  <ReserveButtonText>預約</ReserveButtonText>
                </ReserveButton>
              </Box>
            </ProductDetails>
          </ProductCard>
        ))}
      </ProductsContainer>
    </Container>
  )
}

export default StylistService
