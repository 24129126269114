import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Tag } from 'antd'
import {
  FacebookOutlined,
  TwitterOutlined,
  HomeFilled,
  WechatOutlined,
  InstagramOutlined,
  ShareAltOutlined
} from '@ant-design/icons'
import axios from 'axios'
import moment from 'moment'
import 'moment/locale/zh-cn'
import { API_BASE_URL, PHOTO_URL } from '../utils/apiutils'
import { useParams } from 'react-router-dom'
import Loading from '../utils/Loading'
import {
  FacebookShareButton,
  TwitterShareButton,
  LineShareButton
} from 'react-share'
import { message } from 'antd'
import { FaLine } from 'react-icons/fa6'
import { Helmet } from 'react-helmet-async'

moment.locale('zh-cn') // 設置 moment 使用中文

const Container = styled.div`
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
`

const Breadcrumb = styled.div`
  margin-bottom: 20px;
  font-size: 14px;
  color: #666;
  a {
    color: #1a73e8;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`
const ArticleRow = styled.div`
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`
const ArticleLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 2;
`
const ArticleRight = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
`

const Title = styled.h1`
  font-size: 33px;
  color: #333;
  margin-bottom: 10px;
`

const SubTitle = styled.h2`
  font-size: 18px;
  color: #d5728a;
  margin-bottom: 20px;
`

const AuthorInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  font-size: 14px;
  color: #666;

  .author-name {
    color: #1a73e8;
    margin-right: 5px;
  }

  .read-time {
    margin-left: auto;
  }
`

const ArticleImage = styled.img`
  max-width: 500px;
  max-height: 300px;
  margin: 20px 0 0px 20px;
  @media (max-width: 820px) {
    width: 400px;
    height: 100%;
    margin: 0 auto;
    object-fit: cover;
  }
`

const ArticleContent = styled.div`
  font-size: 16px;
  color: #333;
  line-height: 1.8;
  text-align: left;
  p {
    margin-bottom: 20px;
  }
  img {
    max-width: 400px;
  }
  @media (max-width: 820px) {
    img {
      max-width: 350px;
    }
  }
`

const SocialShare = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;

  .share-icon {
    font-size: 24px;
    margin-right: 15px;
    cursor: pointer;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.1);
    }
  }

  .facebook {
    color: #1877f2;
  }
  .twitter {
    color: #1da1f2;
  }
  .line {
    color: #00b900;
  }
  .wechat {
    color: #07c160;
  }
  .instagram {
    color: #c13584;
  }
  .share {
    color: #555;
  }
`

const TagContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #f0f0f0;
  padding-top: 20px;
`

const StyledTag = styled(Tag)`
  margin: 5px;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 15px;
  border: none;
  background-color: #d5728a;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #d65172;
  }
  @media (max-width: 820px) {
    font-size: 12px;
  }
`

const ArticleDetailPage = () => {
  const { id } = useParams()
  const [article, setArticle] = useState(null)

  // 添加 categoryMap
  const categoryMap = {
    beauty: '美容保養',
    hair: '髮型風格',
    fashion: '時尚話題',
    art: '人文藝術',
    motivation: '心靈勵志',
    surgery: '醫美話題',
    body: '美體養生',
    nail: '美甲設計'
  }

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/articles/${id}`)
        setArticle(response.data.data)
      } catch (error) {
        console.error('Error fetching article:', error)
      }
    }

    fetchArticle()
  }, [id])

  if (!article) {
    return <Loading />
  }

  const shareUrl = window.location.href
  const shareTitle = article.title

  const fallbackShare = () => {
    const textToCopy = `${shareTitle}\n${shareUrl}`
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        message.success('標題和鏈接已複製到剪貼板')
      })
      .catch(() => {
        message.error('複製失敗，請手動複製')
      })
  }

  const handleWeChatShare = () => {
    // 由於微信不提供直接分享的 API，我們可以顯示一個二維碼或指導用戶如何在微信中分享
    message.info('請在微信中打開此頁面並使用分享功能')
    fallbackShare()
  }

  const handleInstagramShare = () => {
    // Instagram 不提供直接分享的 API，我們可以複製鏈接並提示用戶
    fallbackShare()
    message.info('請在 Instagram 應用中粘貼鏈接進行分享')
  }

  const handleGeneralShare = () => {
    fallbackShare()
  }

  return (
    <Container>
      <Helmet>
        <title>{`${article.title} - 美感｜BeauteFeel`}</title>
        <meta
          name='description'
          content={`美感幫你把所有美容、美甲、美髮、睫毛、紋繡、霧唇、霧眉的最新話題都整理好了！所有最新話題與店家設計師專欄都在這裡，看看他們怎麼說！`}
        />
      </Helmet>
      <Breadcrumb>
        <a href='/'>
          <HomeFilled />
        </a>{' '}
        &gt;{' '}
        <a href={`/category/${article.category}`}>
          {categoryMap[article.category] || article.category}
        </a>{' '}
        &gt; {article.title}
      </Breadcrumb>
      <ArticleRow>
        <ArticleLeft>
          <Title>{article.title}</Title>
          <SubTitle>{article.subtitle}</SubTitle>
          <AuthorInfo>
            <div>
              <span className='author-name'>{article.username}</span>
              <span>{moment(article.createdAt).format('LL')}</span>
            </div>
          </AuthorInfo>
          <SocialShare>
            <FacebookShareButton url={shareUrl} quote={shareTitle}>
              <FacebookOutlined className='share-icon facebook' />
            </FacebookShareButton>
            <TwitterShareButton url={shareUrl} title={shareTitle}>
              <TwitterOutlined className='share-icon twitter' />
            </TwitterShareButton>
            <LineShareButton url={shareUrl} title={shareTitle}>
              <FaLine className='share-icon line' />
            </LineShareButton>
            <WechatOutlined
              className='share-icon wechat'
              onClick={handleWeChatShare}
            />
            <InstagramOutlined
              className='share-icon instagram'
              onClick={handleInstagramShare}
            />
            <ShareAltOutlined
              className='share-icon share'
              onClick={handleGeneralShare}
            />
          </SocialShare>
        </ArticleLeft>
        <ArticleRight>
          <ArticleImage
            src={`${PHOTO_URL}${article.image}`}
            alt={article.title}
          />
        </ArticleRight>
      </ArticleRow>
      <ArticleContent dangerouslySetInnerHTML={{ __html: article.content }} />
      <TagContainer>
        {article.tags.map((tag, index) => (
          <StyledTag key={index}>#{tag}</StyledTag>
        ))}
      </TagContainer>
    </Container>
  )
}

export default ArticleDetailPage
