import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Divider, message, Button } from 'antd'
import { FaGoogle, FaFacebook, FaLine } from 'react-icons/fa'
import { useAuth } from '../hooks/AuthContext'
import api from '../utils/api'
import { Helmet } from 'react-helmet-async'

// 移動版組件
const ContainerMobile = styled.div`
  display: flex;
  padding: 50px 16px;
  background: linear-gradient(135deg, #f2b3c0 0%, #d5728a 100%);
  align-items: center;
  justify-content: center;
`

const BoxMobile = styled.div`
  width: 100%;
  max-width: 400px;
  background: #fff;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
`

const TitleMobile = styled.h2`
  text-align: center;
  margin-bottom: 20px;
  color: #333;
`

const FormMobile = styled.form`
  display: flex;
  flex-direction: column;
`

const InputMobile = styled.input`
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
`

const ButtonMobile = styled.button`
  padding: 12px;
  background-color: #4285f4;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 15px;
`

const SocialLoginContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  @media (min-width: 821px) {
    margin-top: 0px;
  }
`

const SocialButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  margin: 0 10px;
  cursor: pointer;
  font-size: 20px;
  color: white;
`

const GoogleButton = styled(SocialButton)`
  background-color: #db4437;
`

const FacebookButton = styled(SocialButton)`
  background-color: #4267b2;
`

const LineButton = styled(SocialButton)`
  background-color: #00c300;
`

const ErrorMessageMobile = styled.p`
  color: red;
`

const HintText = styled.p`
  font-size: 12px;
  color: #888;
  margin-top: 0px;
  margin-bottom: 5px;
  text-align: left;
  @media (min-width: 821px) {
    .HintText {
      margin-bottom: 15px;
    }
  }
`

// PC版組件
const ContainerPC = styled.div`
  display: flex;
  padding: 16px;
  height: 100vh;
  align-items: center;
  justify-content: center;
`

const BoxPC = styled.div`
  position: relative;
  display: flex;
  width: 900px;
  height: 550px;
  background: #fff;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  overflow: hidden;
`

const PanelPC = styled.div`
  position: absolute;
  display: flex;
  width: 50%;
  height: 100%;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  transition: all 0.6s ease-in-out;
  transform: ${({ $show }) => ($show ? 'translateX(0)' : 'translateX(-100%)')};
  opacity: ${({ $show }) => ($show ? '1' : '0')};
  z-index: ${({ $show }) => ($show ? '2' : '1')}; // 增加 z-index
`

const PanelRightPC = styled(PanelPC)`
  right: 0;
  transform: ${({ $show }) => ($show ? 'translateX(0)' : 'translateX(100%)')};
  z-index: ${({ $show }) => ($show ? '2' : '1')};
`

const OverlayPanelPC = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  width: 50%;
  height: 100%;
  color: #fff;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.6s ease-in-out;
  transform: ${({ $isSignUp }) =>
    $isSignUp ? 'translateX(-100%)' : 'translateX(0)'};
  z-index: 10; // 確保覆蓋層始終在最上層
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(135deg, #f2b3c0 0%, #d5728a 100%);
    z-index: -1;
  }
`

const OverlayPanelContentPC = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const FormPC = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const TitlePC = styled.h2`
  margin-bottom: 10px;
  color: #333;
`

const InputPC = styled.input`
  padding: 10px;
  width: 300px;
  border: 1px solid #ddd;
  border-radius: 5px;
`

const ButtonPC = styled(Button)`
  padding: 20px;
  border-radius: 30px;
  border: none;
  background-color: #e14a63;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
`

const ErrorMessagePC = styled.p`
  color: red;
  margin-top: 0px;
`

const InputGroupPC = styled.div`
  margin-bottom: 15px;
  @media (min-width: 821px) {
    margin-bottom: 10px;
  }
`

const Text = styled.div`
  padding: 5px;
  font-size: 16px;
`

// 新增 email 驗證函數
const isValidEmail = (email) => {
  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return re.test(String(email).toLowerCase())
}

const LoginPage = () => {
  const { login, user } = useAuth()
  const [isSignUp, setIsSignUp] = useState(false)
  const [loginData, setLoginData] = useState({ username: '', password: '' })
  const [registerData, setRegisterData] = useState({
    username: '',
    name: '',
    phone: '',
    email: '',
    password: '',
    confirmPassword: ''
  })
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  // 新增用於手機版切換登入/註冊的狀態
  const [isMobileSignUp, setIsMobileSignUp] = useState(false)

  // 切換手機版登入/註冊模式
  const toggleMobileAuthMode = () => {
    setIsMobileSignUp(!isMobileSignUp)
  }

  const toggleAuthMode = () => {
    console.log('toggleAuthMode called, current isSignUp:', isSignUp)
    setIsSignUp(!isSignUp)
    console.log('isSignUp after toggle:', !isSignUp)
  }

  const navigateToPreviousPage = useCallback(() => {
    const { state } = location
    if (state?.from) {
      navigate(state.from)
    } else {
      navigate('/')
    }
  }, [navigate, location])

  useEffect(() => {
    if (user) {
      navigateToPreviousPage()
    }
  }, [user, navigateToPreviousPage])

  const handleLoginSubmit = async (e) => {
    e.preventDefault()
    setError('')
    setLoading(true)
    try {
      await login(loginData)
      message.success('已成功登入！')
    } catch (error) {
      setError('登入失敗，請檢查您的帳號和密碼')
    } finally {
      setLoading(false)
    }
  }

  const handleRegisterSubmit = async (e) => {
    e.preventDefault()
    setError('')

    // 去除所有字段的首尾空白
    const trimmedUsername = registerData.username.trim()
    const trimmedEmail = registerData.email.trim()
    const trimmedPassword = registerData.password.trim()
    const trimmedConfirmPassword = registerData.confirmPassword.trim()
    const trimmedName = registerData.name.trim()
    const trimmedPhone = registerData.phone.trim()

    // 檢查是否有空白字段
    if (
      !trimmedUsername ||
      !trimmedEmail ||
      !trimmedPassword ||
      !trimmedConfirmPassword ||
      !trimmedName ||
      !trimmedPhone
    ) {
      setError('所有字段均為必填項，不能留空')
      return
    }

    // 驗證帳號
    if (!/^[a-zA-Z0-9]{1,50}$/.test(trimmedUsername)) {
      setError('帳號只能包含英文字母和數字，最多50個字符')
      return
    }

    // 驗證手機號
    if (!/^[0-9]{10}$/.test(trimmedPhone)) {
      setError('請輸入有效的手機號碼')
      return
    }

    // 驗證電子信箱
    if (!isValidEmail(trimmedEmail)) {
      setError('請輸入有效的電子信箱地址')
      return
    }

    // 驗證密碼
    if (trimmedPassword.length < 6) {
      setError('密碼至少需要6位英數字')
      return
    }

    // 檢查密碼是否包含至少一個字母和一個數字
    if (!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/.test(trimmedPassword)) {
      setError('密碼必須包含至少一個字母和一個數字')
      return
    }

    if (trimmedPassword !== trimmedConfirmPassword) {
      setError('密碼和確認密碼不匹配')
      return
    }

    setLoading(true)
    try {
      // 將帳號轉換為小
      const lowercaseUsername = trimmedUsername.toLowerCase()

      await api.post(`/api/auth/register`, {
        username: lowercaseUsername,
        email: trimmedEmail,
        password: trimmedPassword,
        name: trimmedName,
        phone: trimmedPhone
      })

      console.log('registerData', {
        username: lowercaseUsername,
        email: trimmedEmail
      })
      message.success('註冊成功，請檢查您的電子信箱以完成電子郵件驗證。')
      navigate('/check-email')
    } catch (error) {
      setError(error.response?.data?.error || '註冊失敗，請檢查您的信息')
    } finally {
      setLoading(false)
    }
  }

  const handleGoogleLogin = () => {
    // 根据环境选择正确的 URL
    const baseUrl =
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:8080'
        : 'https://beautefeel.com'

    window.location.href = `${baseUrl}/api/auth/google`
  }

  const handleFacebookLogin = () => {
    // 實現 Facebook 登入邏輯
  }

  const handleLineLogin = () => {
    window.location.href = `https://beautefeel.com/api/auth/line`
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    handleResize() // 立即調用以設置初始狀態
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (isMobile) {
    return (
      <ContainerMobile>
        <Helmet>
          <title>{`線上預約會員登入 - 美感｜BeauteFeel`}</title>
          <meta
            name='description'
            content={`全台美容線上預約平台，不論美甲美容美髮美睫霧唇霧眉，輕鬆一鍵查詢並完成預約，找到最符合您需求的美容專家!`}
          />
        </Helmet>
        <BoxMobile>
          <TitleMobile>
            {isMobileSignUp ? '新朋友？歡迎加入' : '歡迎回來'}
          </TitleMobile>
          {isMobileSignUp ? (
            <FormMobile onSubmit={handleRegisterSubmit}>
              {error && <ErrorMessageMobile>{error}</ErrorMessageMobile>}
              <InputMobile
                type='text'
                style={{ marginBottom: '3px' }}
                placeholder='帳號'
                value={registerData.username}
                onChange={(e) =>
                  setRegisterData({
                    ...registerData,
                    username: e.target.value
                  })
                }
              />
              <HintText>帳號只能包含英文字母和數字，最多50個字符</HintText>
              <InputMobile
                type='text'
                style={{ marginBottom: '3px' }}
                placeholder='姓名'
                value={registerData.name}
                onChange={(e) =>
                  setRegisterData({
                    ...registerData,
                    name: e.target.value
                  })
                }
              />
              <InputMobile
                type='phone'
                placeholder='手機號碼'
                value={registerData.phone}
                onChange={(e) =>
                  setRegisterData({ ...registerData, phone: e.target.value })
                }
              />
              <InputMobile
                type='email'
                placeholder='電子信箱'
                value={registerData.email}
                onChange={(e) =>
                  setRegisterData({ ...registerData, email: e.target.value })
                }
              />
              <InputMobile
                type='password'
                placeholder='密碼'
                style={{ marginBottom: '3px' }}
                value={registerData.password}
                onChange={(e) =>
                  setRegisterData({ ...registerData, password: e.target.value })
                }
              />
              <HintText>
                密碼至少需要6位，必須包含至少一個字母和一個數字
              </HintText>
              <InputMobile
                type='password'
                placeholder='確認密碼'
                style={{ marginBottom: '3px' }}
                value={registerData.confirmPassword}
                onChange={(e) =>
                  setRegisterData({
                    ...registerData,
                    confirmPassword: e.target.value
                  })
                }
              />
              <HintText>請再次輸入密碼以確認</HintText>
              <InputMobile placeholder='註冊碼（選填）' />
              <ButtonMobile type='submit' disabled={loading}>
                {loading ? '註冊中...' : '註冊'}
              </ButtonMobile>
            </FormMobile>
          ) : (
            <FormMobile onSubmit={handleLoginSubmit}>
              {error && <ErrorMessageMobile>{error}</ErrorMessageMobile>}
              <InputMobile
                type='text'
                placeholder='帳號'
                value={loginData.username}
                onChange={(e) =>
                  setLoginData({ ...loginData, username: e.target.value })
                }
              />
              <InputMobile
                type='password'
                placeholder='密碼'
                value={loginData.password}
                onChange={(e) =>
                  setLoginData({ ...loginData, password: e.target.value })
                }
              />
              <ButtonMobile type='submit' disabled={loading}>
                {loading ? '登入中...' : '登入'}
              </ButtonMobile>
            </FormMobile>
          )}
          <Divider>快速登入</Divider>
          <SocialLoginContainer>
            <GoogleButton onClick={handleGoogleLogin}>
              <FaGoogle />
            </GoogleButton>
            {/* <FacebookButton onClick={handleFacebookLogin}>
              <FaFacebook />
            </FacebookButton> */}
            <LineButton onClick={handleLineLogin}>
              <FaLine />
            </LineButton>
          </SocialLoginContainer>
          <Text style={{ textAlign: 'center', marginTop: '20px' }}>
            {isMobileSignUp ? '已有帳號？' : '還沒有帳號？'}{' '}
            <button
              onClick={toggleMobileAuthMode}
              style={{
                background: 'none',
                border: 'none',
                color: '#1890ff',
                cursor: 'pointer',
                padding: 0,
                font: 'inherit',
                textDecoration: 'underline'
              }}
            >
              {isMobileSignUp ? '登入' : '註冊'}
            </button>
          </Text>
        </BoxMobile>
      </ContainerMobile>
    )
  }

  return (
    <ContainerPC>
      <Helmet>
        <title>{`會員登入註冊 ｜ 全台線上即時預約平台優惠推薦 - 美感｜BeauteFeel`}</title>
        <meta
          name='description'
          content={`全台美容線上預約平台，不論美甲美容美髮美睫霧唇霧眉，輕鬆一鍵查詢並完成預約，找到最符合您需求的美容專家!`}
        />
      </Helmet>
      <BoxPC>
        <PanelPC $show={!isSignUp}>
          <FormPC onSubmit={handleLoginSubmit}>
            <TitlePC>登入</TitlePC>
            {error && <ErrorMessagePC>{error}</ErrorMessagePC>}
            <InputGroupPC>
              <InputPC
                type='text'
                placeholder='帳號'
                value={loginData.username}
                onChange={(e) =>
                  setLoginData({ ...loginData, username: e.target.value })
                }
              />
            </InputGroupPC>
            <InputGroupPC>
              <InputPC
                type='password'
                placeholder='密碼'
                value={loginData.password}
                onChange={(e) =>
                  setLoginData({ ...loginData, password: e.target.value })
                }
              />
            </InputGroupPC>
            <ButtonPC type='primary' htmlType='submit' disabled={loading}>
              {loading ? 'Loading...' : '登入'}
            </ButtonPC>
            <div style={{ marginTop: '20px' }}></div>
            <Divider>快速登入</Divider>
            <SocialLoginContainer>
              <GoogleButton onClick={handleGoogleLogin}>
                <FaGoogle />
              </GoogleButton>
              {/* <FacebookButton onClick={handleFacebookLogin}>
                <FaFacebook />
              </FacebookButton> */}
              <LineButton onClick={handleLineLogin}>
                <FaLine />
              </LineButton>
            </SocialLoginContainer>
          </FormPC>
        </PanelPC>
        <PanelRightPC $show={isSignUp}>
          <FormPC onSubmit={handleRegisterSubmit}>
            <TitlePC>新朋友？歡迎加入</TitlePC>
            {error && <ErrorMessagePC>{error}</ErrorMessagePC>}
            <InputGroupPC>
              <InputPC
                type='text'
                placeholder='帳號'
                value={registerData.username}
                onChange={(e) =>
                  setRegisterData({
                    ...registerData,
                    username: e.target.value
                  })
                }
              />
              <HintText>帳號只能包含英文字母和數字，最多50個字符</HintText>
            </InputGroupPC>
            <InputGroupPC>
              <InputPC
                type='text'
                placeholder='姓名'
                value={registerData.name}
                onChange={(e) =>
                  setRegisterData({
                    ...registerData,
                    name: e.target.value
                  })
                }
              />
            </InputGroupPC>
            <InputGroupPC>
              <InputPC
                type='phone'
                placeholder='手機號碼'
                value={registerData.phone}
                onChange={(e) =>
                  setRegisterData({ ...registerData, phone: e.target.value })
                }
              />
            </InputGroupPC>
            <InputGroupPC>
              <InputPC
                type='email'
                placeholder='電子信箱'
                value={registerData.email}
                onChange={(e) =>
                  setRegisterData({ ...registerData, email: e.target.value })
                }
              />
            </InputGroupPC>
            <InputGroupPC>
              <InputPC
                type='password'
                placeholder='密碼'
                value={registerData.password}
                onChange={(e) =>
                  setRegisterData({ ...registerData, password: e.target.value })
                }
              />
              <HintText>
                密碼至少需要6位，必須包含至少一個字母和一個數字
              </HintText>
            </InputGroupPC>
            <InputGroupPC>
              <InputPC
                type='password'
                placeholder='確認密碼'
                value={registerData.confirmPassword}
                onChange={(e) =>
                  setRegisterData({
                    ...registerData,
                    confirmPassword: e.target.value
                  })
                }
              />
              <HintText>請再次輸入密碼以確認</HintText>
            </InputGroupPC>
            {/* 新增註冊碼欄位 */}
            <InputGroupPC>
              <InputPC placeholder='註冊碼（選填）' />
            </InputGroupPC>
            <ButtonPC type='primary' htmlType='submit' disabled={loading}>
              {loading ? 'Loading...' : '註冊'}
            </ButtonPC>
          </FormPC>
        </PanelRightPC>
        <OverlayPanelPC $isSignUp={isSignUp}>
          <OverlayPanelContentPC>
            <h3>美感 BeauteFeel</h3>
            <TitlePC style={{ color: 'white' }}>
              {isSignUp ? '歡迎回來！老朋友' : '新朋友？歡迎加入'}
            </TitlePC>
            <p>
              {isSignUp
                ? '請使用您的個人信息登入以保持聯繫'
                : '使用您的電子信箱進行註冊'}
            </p>
            <ButtonPC onClick={toggleAuthMode} disabled={loading}>
              {isSignUp ? '登入' : '註冊'}
            </ButtonPC>
          </OverlayPanelContentPC>
        </OverlayPanelPC>
      </BoxPC>
    </ContainerPC>
  )
}

export default LoginPage
